import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isTabbedSidebarOpen: false,
  activeTab: 0, //Default to the first tab (SEARCHES)
  isChevronSidebarButtonDisabled: true,
};

const tabbedSidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    openTabbedSidebar: (state) => {
      state.isTabbedSidebarOpen = true;
    },
    closeTabbedSidebar: (state) => {
      state.isTabbedSidebarOpen = false;
    },
    toggleTabbedSidebar: (state) => {
      state.isTabbedSidebarOpen = !state.isTabbedSidebarOpen;
    },
    setActiveTabReducer: (state, action) => {
      state.activeTab = action.payload;
    },
    setIsChevronSidebarButtonDisabledReducer: (state, action) => {
      state.isChevronSidebarButtonDisabled = action.payload;
    },
  },
});

export const {
  openTabbedSidebar,
  closeTabbedSidebar,
  toggleTabbedSidebar,
  setActiveTabReducer,
  isChevronSidebarButtonDisabled,
  setIsChevronSidebarButtonDisabledReducer,
} = tabbedSidebarSlice.actions;

export default tabbedSidebarSlice.reducer;
