import { useState, useMemo, useCallback } from "react";
import { CircularProgress, Box } from "@mui/material";
import {
  ExtendedStyledSpinner,
  SpinnerContainer,
  StyledAutocomplete,
  StyledBox,
  StyledOption,
  StyledTextField,
  StyledTypography,
  VerticalLine,
} from "../styles/SelectSearchWithAutocomplete/SelectSearchWithAutocomplete.styles";

const AutocompleteSearch = ({
  val,
  changeVal,
  textHeader,
  textSelect,
  last = false,
  items,
  small,
  load,
  pageSize = 20, // Default page size
}) => {
  const [inputValue, setInputValue] = useState("");
  const [visibleOptions, setVisibleOptions] = useState([]);
  const [hasMoreOptions, setHasMoreOptions] = useState(false);
  const [isLoadingMoreOptions, setIsLoadingMoreOptions] = useState(false);

  const SCROLL_TOLERANCE = 5; // Scrolling tolerance in pixels

  const normalizeString = (str) => str?.toLowerCase().trim();

  // Filter options based on input value
  const filteredOptions = useMemo(() => {
    const normalizedInput = normalizeString(inputValue);
    const filtered = items.filter((item) =>
      normalizeString(item.label)?.includes(normalizedInput)
    );
    return filtered;
  }, [inputValue, items]);

  // Update visible options and hasMoreOptions state
  const updateVisibleOptions = useCallback(() => {
    const initialOptions = filteredOptions.slice(0, pageSize);
    setVisibleOptions(initialOptions);
    const moreOptions = filteredOptions.length > initialOptions.length;
    setHasMoreOptions(moreOptions);
  }, [filteredOptions, pageSize]);

  // Handle input value change
  const handleInputChange = useCallback((event, newInputValue) => {
    setInputValue(newInputValue);
  }, []);

  // Load more options when scrolling
  const handleLoadMore = () => {
    if (!isLoadingMoreOptions && hasMoreOptions) {
      setIsLoadingMoreOptions(true);

      setTimeout(() => {
        const nextOptions = filteredOptions.slice(
          visibleOptions.length,
          visibleOptions.length + pageSize
        );

        // Update visible options and hasMoreOptions
        setVisibleOptions((prev) => {
          const uniqueOptions = [
            ...prev,
            ...nextOptions.filter(
              (option) =>
                !prev.some((prevOption) => prevOption.value === option.value)
            ),
          ];
          return uniqueOptions;
        });

        const moreOptions =
          filteredOptions.length > visibleOptions.length + nextOptions.length;
        setHasMoreOptions(moreOptions);
        setIsLoadingMoreOptions(false);
      }, 500);
    }
  };

  // Update visible options when filteredOptions change
  useMemo(() => {
    updateVisibleOptions();
  }, [filteredOptions, updateVisibleOptions]);

  // Handle option selection
  const handleChange = useCallback(
    (event, newValue) => {
      changeVal(newValue);
      setInputValue(newValue ? newValue.label : "");
    },
    [changeVal]
  );

  return (
    <StyledBox small={small}>
      <StyledTypography small={small} itemsLength={items.length}>
        {textHeader}
      </StyledTypography>
      {!load ? (
        <StyledAutocomplete
          value={items[val]}
          className="custom-autocomplete"
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          options={visibleOptions}
          isOptionEqualToValue={(option, value) => option.value === value}
          getOptionLabel={(option) => (option ? option.label : "")}
          renderOption={(props, option) => {
            return (
              <StyledOption {...props} key={`${option.value}-${option.label}`}>
                {option.label}
              </StyledOption>
            );
          }}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label={inputValue ? "" : textSelect}
              variant="outlined"
              size={small ? "small" : "medium"}
              val={val}
              InputLabelProps={{
                shrink: false,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {load || isLoadingMoreOptions ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        style={{ color: "#52975c" }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          ListboxProps={{
            onScroll: (event) => {
              const listboxNode = event.currentTarget;

              if (
                listboxNode.scrollTop + listboxNode.clientHeight >=
                  listboxNode.scrollHeight - SCROLL_TOLERANCE &&
                hasMoreOptions &&
                !isLoadingMoreOptions
              ) {
                handleLoadMore();
              }
            },
          }}
        />
      ) : (
        <SpinnerContainer>
          <ExtendedStyledSpinner small={small} style={{ color: "#52975c" }} />
        </SpinnerContainer>
      )}
      {!last && <VerticalLine small={small} />}
    </StyledBox>
  );
};

export default AutocompleteSearch;
