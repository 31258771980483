import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLandSearchSidebarOpen,
  selectLandSearches,
  selectLandSearchStatus,
} from "../redux/selectors/landSearchSelectors";
import { fetchLandSearchesAction } from "../redux/actions/landSearchActions";
import { toggleLandsSearchSideBar } from "../redux/reducers/landSearchReducer";
import { useTabbedSidebar } from "./useTabbedSidebar";

const useLandSearchResults = (accessToken, isHomePageRoute) => {
  const dispatch = useDispatch();

  const isLandSearchSidebarOpen = useSelector(selectIsLandSearchSidebarOpen);
  const landSearches = useSelector(selectLandSearches);
  const landSearchesStatus = useSelector(selectLandSearchStatus);
  const { openSidebar } = useTabbedSidebar();

  const toggleLandSearchSideBar = () => {
    dispatch(toggleLandsSearchSideBar());
  };

  useEffect(() => {
    if (accessToken && isHomePageRoute) {
      dispatch(fetchLandSearchesAction(accessToken));
    }
  }, [accessToken, dispatch, isHomePageRoute]);

  useEffect(() => {
    if (landSearchesStatus === "success" && isHomePageRoute) {
      openSidebar();
    }
  }, [landSearchesStatus, dispatch, isHomePageRoute]);

  return {
    isLandSearchSidebarOpen,
    landSearches,
    landSearchesStatus,
    toggleLandSearchSideBar,
  };
};

export default useLandSearchResults;
