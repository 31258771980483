import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import CreateModal from "../CreateModal";
import { useState } from "react";
import { useAnalyzeLand } from "../../new_hooks/useAnalyzeLand";
import {
  AnalyzeLandBox,
  AnalyzeLandModalTitle,
  StyledDragAndDropBox,
  StyledFileUploadOutlined,
  StyledMotionDiv,
  StyledUploadButton,
} from "../../styles/AnalyzeLand/AnalyzeLandModal.styles";
import FilePreview from "./FilePreview";
import { AnimatePresence } from "framer-motion";
import LoadingMessage from "./LoadingMessage";
import { ErrorMessage } from "../../styles/AnalyzeLand/ErrorMessage.styles";
import { ErrorOutline } from "@mui/icons-material";
import { useTabbedSidebar } from "../../new_hooks/useTabbedSidebar";

const AnalyzeLandModal = ({ setOpen, landID }) => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [dragAndDropError, setDragAndDropError] = useState({
    status: false,
    message: "",
  });
  const [modalStatus, setModalStatus] = useState("idle"); // 'idle', 'uploading', 'analyzing', 'complete', 'error'
  const [modalClosingTriggered, setModalClosingTriggered] = useState(false);
  const [detailedError, setDetailedError] = useState("");

  const { isSidebarOpen, closeSidebar } = useTabbedSidebar();
  const {
    uploadAnalyzeLandFile,
    setAnalyzeLandModalOpen,
    uploadError,
    responseUrl,
    setResponseUrl,
    fetchLandAnalysisOrchestrator,
    setShowAnalyzeLandResultsUI,
    analysisResultPoll,
    setAnalysisResultValue,
    setCompletedAnalysis,
    isPolling,
    analysisError,
  } = useAnalyzeLand();

  const motionMessageVariants = {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
    transition: { duration: 0.3 },
  };

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const statusTitles = {
    uploading: "Uploading file",
    analyzing: "Analyzing land",
    error: "There was an error during the analysis. Please try again later",
  };

  useEffect(() => {
    setAnalyzeLandModalOpen(true);
    if (responseUrl) setResponseUrl(null);
    setCompletedAnalysis(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSidebarOpen) closeSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarOpen]);

  const setOpenModal = (isOpen) => {
    if (!isOpen) {
      setModalStatus("idle");
      setFile(null);
      setResponseUrl(null);
    }

    setOpen(isOpen);
    setAnalyzeLandModalOpen(isOpen);
    setShowAnalyzeLandResultsUI(true);
  };

  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const validFileTypes = [
        "application/vnd.google-earth.kmz",
        "application/vnd.google-earth.kml+xml",
      ];
      const maxSizeInBytes = 50 * 1024;

      const fileType = selectedFile.type || "";
      const isValidType =
        validFileTypes.includes(fileType) ||
        selectedFile.name.endsWith(".kmz") ||
        selectedFile.name.endsWith(".kml");

      if (!isValidType) {
        setDragAndDropError({
          status: true,
          message: "Only .kmz and .kml files are allowed.",
        });
        return;
      }

      if (selectedFile.size > maxSizeInBytes) {
        setDragAndDropError({
          status: true,
          message: "File size exceeds 50 KB limit.",
        });
        return;
      }

      setFile(selectedFile);
      setDragAndDropError({ status: false, message: "" });
    }
  };

  useEffect(() => {
    let timeoutId;
    if (file && dragAndDropError.status) {
      timeoutId = setTimeout(() => {
        setDragAndDropError({ status: false, message: "" });
      }, 5000);
    }

    return () => clearTimeout(timeoutId);
  }, [file, dragAndDropError.status]);

  const onDeleteFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const upload = () => {
    if (file) {
      setModalStatus("uploading");
      uploadAnalyzeLandFile({ file: file });
      setAnalysisResultValue(null);
    }
  };

  useEffect(() => {
    if (file && !responseUrl && uploadError) {
      setModalStatus("idle");
      setDragAndDropError({
        status: true,
        message:
          "There was an error on the upload. Try again later or with another file",
      });
    }
  }, [file, responseUrl, uploadError]);

  useEffect(() => {
    if (!!responseUrl && modalStatus === "uploading") {
      fetchLandAnalysisOrchestrator({ responseUrl });
      setModalStatus("analyzing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseUrl, modalStatus]);

  useEffect(() => {
    if (
      analysisResultPoll?.status === "success" &&
      modalStatus === "analyzing" &&
      !modalClosingTriggered
    ) {
      setModalStatus("completed");
      return;
    }

    if (
      modalStatus !== "idle" &&
      (analysisResultPoll?.status === "error" || analysisError)
    ) {
      if (analysisError) {
        setDetailedError(analysisError);
      }
      setModalStatus("error");
    }
  }, [
    analysisResultPoll?.status,
    modalClosingTriggered,
    modalStatus,
    isPolling,
    analysisError,
  ]);

  useEffect(() => {
    if (modalStatus === "completed") {
      setShowAnalyzeLandResultsUI(true);
      setTimeout(() => {
        setOpenModal(false);
        setAnalyzeLandModalOpen(false);
        setModalClosingTriggered(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalStatus]);

  return (
    <Box>
      <CreateModal
        height={"558px"}
        width={"550px"}
        setOpen={setOpenModal}
        content={
          <AnalyzeLandBox>
            {modalStatus === "idle" && (
              <>
                <AnalyzeLandModalTitle>Upload .kmz file</AnalyzeLandModalTitle>
                <StyledDragAndDropBox>
                  <StyledFileUploadOutlined />
                  <Box>
                    <Typography>
                      Drag and drop your file here or{" "}
                      <span className="select-file">
                        select a file from your computer
                      </span>
                    </Typography>
                    <Typography>Max size 50 KB</Typography>
                  </Box>
                  <input
                    ref={fileInputRef}
                    type="file"
                    onChange={onFileChange}
                    accept=".kmz, .kml"
                  />
                </StyledDragAndDropBox>
                <AnimatePresence>
                  {file && (
                    <StyledMotionDiv
                      key={file.name}
                      variants={motionMessageVariants}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                    >
                      <FilePreview
                        fileName={file.name}
                        fileSize={file.size}
                        onDeleteFile={onDeleteFile}
                      />
                    </StyledMotionDiv>
                  )}
                </AnimatePresence>
                <AnimatePresence>
                  {dragAndDropError.status ? (
                    <StyledMotionDiv
                      variants={motionMessageVariants}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                    >
                      <ErrorMessage>
                        <ErrorOutline />
                        <Typography> {dragAndDropError.message}</Typography>
                      </ErrorMessage>
                    </StyledMotionDiv>
                  ) : null}
                </AnimatePresence>
                <StyledUploadButton disabled={file === null} onClick={upload}>
                  Upload
                </StyledUploadButton>
              </>
            )}

            {statusTitles[modalStatus] && (
              <AnimatePresence>
                <StyledMotionDiv
                  variants={fadeVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  <LoadingMessage
                    title={
                      modalStatus === "error" && detailedError
                        ? detailedError
                        : statusTitles[modalStatus]
                    }
                    showLoader={modalStatus !== "error"}
                  />
                </StyledMotionDiv>
              </AnimatePresence>
            )}
            {modalStatus === "analysisError" && (
              <AnimatePresence>
                <StyledMotionDiv
                  variants={fadeVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  <LoadingMessage title={analysisError} showLoader={false} />
                </StyledMotionDiv>
              </AnimatePresence>
            )}
          </AnalyzeLandBox>
        }
      />
    </Box>
  );
};

export default AnalyzeLandModal;
