import { Box } from "@mui/material";
import styled from "styled-components";

export const ErrorMessage = styled(Box)`
  border: 1px solid #d23f63;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #fceef1;
  color: #d23f63;
  p {
    font-weight: 600;
    font-size: 14px;
  }
`;
