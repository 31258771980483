import { useSelector, useDispatch } from "react-redux";
import {
  closeTabbedSidebar,
  openTabbedSidebar,
  setActiveTabReducer,
  setIsChevronSidebarButtonDisabledReducer,
  toggleTabbedSidebar,
} from "../redux/reducers/tabbedSidebarSlice";

export const useTabbedSidebar = () => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(
    (state) => state.sidebar.isTabbedSidebarOpen
  );
  const activeTab = useSelector((state) => state.sidebar.activeTab);
  const isChevronButtonDisabled = useSelector(
    (state) => state.sidebar.isChevronSidebarButtonDisabled
  );

  const openSidebar = () => {
    dispatch(openTabbedSidebar());
  };

  const toggleSidebar = () => {
    dispatch(toggleTabbedSidebar());
  };

  const setActiveTab = (tab) => {
    dispatch(setActiveTabReducer(tab));
  };

  const setIsChevronSidebarButtonDisabled = (isDisabled) => {
    dispatch(setIsChevronSidebarButtonDisabledReducer(isDisabled));
  };

  const closeSidebar = () => {
    dispatch(closeTabbedSidebar());
    dispatch(setIsChevronSidebarButtonDisabledReducer(false));
  };

  return {
    openSidebar,
    toggleSidebar,
    closeSidebar,
    isSidebarOpen,
    setActiveTab,
    activeTab,
    isChevronButtonDisabled,
    setIsChevronSidebarButtonDisabled,
  };
};
