const analyzeLandSelector = (state) => state.analyzeLand;

export const analyzeLandUploadIsLoadingSelector = (state) =>
  analyzeLandSelector(state).uploadIsLoading;
export const isAnalyzeLandOpenSelector = (state) =>
  analyzeLandSelector(state).isAnalyzeLandOpen;
export const responseUrlSelector = (state) =>
  analyzeLandSelector(state).responseUrl;
export const requestAnalyzeLandOpenSelector = (state) =>
  analyzeLandSelector(state).uploadRequest;
export const analyzeLandUploadErrorSelector = (state) =>
  analyzeLandSelector(state).uploadError;
export const analyzeLandOrchestratorSelector = (state) =>
  analyzeLandSelector(state).landAnalysisProcessOrchestrator;
export const analyzeLandOrchestratorErrorSelector = (state) =>
  analyzeLandSelector(state).landAnalysisOrchestratorError;
export const analyzeLandOrchestratorIsLoadingSelector = (state) =>
  analyzeLandSelector(state).landAnalysisIsLoading;
export const pollingOrchestratorCompletedSelector = (state) =>
  analyzeLandSelector(state).completedAnalysis;
export const pollingOrchestratorAnalysisResultSelector = (state) =>
  analyzeLandSelector(state).analysisResult;
export const pollingOrchestratorIsPollingtSelector = (state) =>
  analyzeLandSelector(state).isPolling;
export const showAnalyzeLandResultsUISelector = (state) =>
  analyzeLandSelector(state).showAnalyzeLandResultsUI;
export const pollingErrorAnalysisResultSelector = (state) =>
  analyzeLandSelector(state).analysisError;
