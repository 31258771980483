import {
  uploadFileAnalyzeLand,
  toggleAnalyzeLandModal,
  setAnalyzeLandOpenModal,
  setAnalyzeLandResponseUrl,
  setLandAnalysisProcessOrchestrator,
  startPolling,
  stopPolling,
  handleShowAnalyzeLandResultsUI,
  setCompletedLandAnalysis,
  setAnalysisResult,
} from "../redux/actions/analyzeLand";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useAuth0AccessToken from "../hooks/authHook";
import {
  analyzeLandOrchestratorSelector,
  analyzeLandUploadErrorSelector,
  analyzeLandUploadIsLoadingSelector,
  isAnalyzeLandOpenSelector,
  pollingErrorAnalysisResultSelector,
  pollingOrchestratorAnalysisResultSelector,
  pollingOrchestratorCompletedSelector,
  pollingOrchestratorIsPollingtSelector,
  requestAnalyzeLandOpenSelector,
  responseUrlSelector,
  showAnalyzeLandResultsUISelector,
} from "../redux/selectors/analyzeLand";
import { useEffect, useState } from "react";
import { LngLatBounds } from "mapbox-gl";

export const useAnalyzeLand = (options = {}) => {
  const { mapRef = null } = options;
  const [geojsonDataAnalyzeLand, setGeojsonDataAnalyzeLand] = useState();
  const [analyze, setAnalyze] = useState(false);
  const dispatch = useDispatch();
  const { accessToken } = useAuth0AccessToken();
  const uploadIsLoading = useSelector(analyzeLandUploadIsLoadingSelector);
  const isAnalyzeLandModalOpen = useSelector(isAnalyzeLandOpenSelector);
  const responseUrl = useSelector(responseUrlSelector);
  const uploadError = useSelector(analyzeLandUploadErrorSelector);
  const uploadRequest = useSelector(requestAnalyzeLandOpenSelector);
  const landAnalysisProcessOrchestrator = useSelector(
    analyzeLandOrchestratorSelector
  );
  const completedAnalysisPoll = useSelector(
    pollingOrchestratorCompletedSelector
  );
  const analysisResultPoll = useSelector(
    pollingOrchestratorAnalysisResultSelector
  );
  const showAnalyzeLandResultsUI = useSelector(
    showAnalyzeLandResultsUISelector
  );
  const isPolling = useSelector(pollingOrchestratorIsPollingtSelector);
  const analysisError = useSelector(pollingErrorAnalysisResultSelector);

  const statusQueryGetUri = landAnalysisProcessOrchestrator?.statusQueryGetUri;
  const uploadAnalyzeLandFile = (params) => {
    dispatch(uploadFileAnalyzeLand({ ...params, token: accessToken }));
  };

  const toggleAnalyzeLand = () => {
    dispatch(toggleAnalyzeLandModal());
  };

  const setAnalyzeLandModalOpen = (shouldOpen) => {
    dispatch(setAnalyzeLandOpenModal(shouldOpen));
  };

  const setResponseUrl = (url) => {
    dispatch(setAnalyzeLandResponseUrl(url));
  };

  const fetchLandAnalysisOrchestrator = ({ responseUrl }) => {
    dispatch(
      setLandAnalysisProcessOrchestrator({
        url: responseUrl,
        token: accessToken,
      })
    );
  };

  const setShowAnalyzeLandResultsUI = (show) => {
    dispatch(handleShowAnalyzeLandResultsUI(show));
  };

  useEffect(() => {
    if (statusQueryGetUri) {
      dispatch(startPolling(statusQueryGetUri));
    }

    return () => {
      dispatch(stopPolling());
    };
  }, [statusQueryGetUri, dispatch]);

  const setCompletedAnalysis = (completed) => {
    dispatch(setCompletedLandAnalysis(completed));
  };

  const setAnalysisResultValue = (value) => {
    dispatch(setAnalysisResult(value));
  };

  useEffect(() => {
    if (
      showAnalyzeLandResultsUI &&
      analysisResultPoll?.status === "success" &&
      analysisResultPoll.result
    ) {
      const parsedGeojson = JSON.parse(
        analysisResultPoll.result
      ).analyzed_land_geometry;
      setGeojsonDataAnalyzeLand(parsedGeojson);
    }
  }, [showAnalyzeLandResultsUI, analysisResultPoll]);

  useEffect(() => {
    if (geojsonDataAnalyzeLand && mapRef?.current) {
      const bounds = new LngLatBounds();

      geojsonDataAnalyzeLand.features.forEach((feature) => {
        feature.geometry.coordinates[0].forEach((coord) => {
          bounds.extend(coord);
        });
      });
      if (mapRef.current.getMap) {
        mapRef?.current.getMap().flyTo({
          center: bounds.getCenter(),
          zoom: 12.5,
          essential: true,
        });
      }
    }
  }, [geojsonDataAnalyzeLand, mapRef]);

  useEffect(() => {
    if (isAnalyzeLandModalOpen) {
      setAnalyze(true);
    }
  }, [isAnalyzeLandModalOpen]);

  return {
    uploadAnalyzeLandFile,
    uploadIsLoading,
    isAnalyzeLandModalOpen,
    toggleAnalyzeLand,
    setAnalyzeLandModalOpen,
    responseUrl,
    setResponseUrl,
    uploadError,
    uploadRequest,
    fetchLandAnalysisOrchestrator,
    landAnalysisProcessOrchestrator,
    completedAnalysisPoll,
    analysisResultPoll,
    showAnalyzeLandResultsUI,
    setShowAnalyzeLandResultsUI,
    setCompletedAnalysis,
    setAnalysisResultValue,
    isPolling,
    geojsonDataAnalyzeLand,
    analyze,
    setAnalyze,
    analysisError,
    //handleClickForError,
  };
};
