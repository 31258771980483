export const SAVE_OUTPUT_LANDS = "SAVE_OUTPUT_LANDS";
export const SAVE_OUTPUT_LANDS_SUCCESS = "SAVE_OUTPUT_LANDS_SUCCESS";
export const SAVE_OUTPUT_LANDS_FAILURE = "SAVE_OUTPUT_LANDS_FAILURE";

export const SET_LOCKED_SEARCH_ENTITY = "SET_LOCKED_SEARCH_ENTITY";

export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";
export const CLEAR_REDIRECT_TO_LOGIN = "CLEAR_REDIRECT_TO_LOGIN";
export const RESET_STORE = "RESET_STORE";

export const GET_OUTPUT_LANDS = "GET_OUTPUT_LANDS";
export const GET_OUTPUT_LANDS_SUCCESS = "GET_OUTPUT_LANDS_SUCCESS";
export const GET_OUTPUT_LANDS_FAILURE = "GET_OUTPUT_LANDS_FAILURE";
export const GET_SELECTED_LANDS_RESULTS = "GET_SELECTED_LANDS_RESULTS";
export const GET_SELECTED_LANDS_RESULTS_SUCCESS =
  "GET_SELECTED_LANDS_RESULTS_SUCCESS";
export const GET_SELECTED_LANDS_RESULTS_FAILURE =
  "GET_SELECTED_LANDS_RESULTS_FAILURE";

export const GET_PRE_PROCESSED_SEARCHES_INPUTS =
  "GET_PRE_PROCESSED_SEARCHES_INPUTS";
export const GET_PRE_PROCESSED_SEARCHES_INPUTS_SUCCESS =
  "GET_PRE_PROCESSED_SEARCHES_INPUTS_SUCCESS";
export const GET_PRE_PROCESSED_SEARCHES_INPUTS_FAILURE =
  "GET_PRE_PROCESSED_SEARCHES_INPUTS_FAILURE";

export const INCREMENT_STEP = "INCREMENT_STEP";
export const DECREMENT_STEP = "DECREMENT_STEP";

export const CLEAR_RESULTS = "CLEAR_RESULTS";

export const GET_USER = "GET_USER";
export const CLEAR_USER_INFO = "CLEAR_USER_INFO";
export const GET_USER_RESULT_SUCCESS = "GET_USER_RESULT_SUCCESS";
export const GET_USER_RESULT_FAILURE = "GET_USER_RESULT_FAILURE";

export const SET_INPUT_SEARCH_LABELS = "SET_INPUT_SEARCH_LABELS";

export const GET_FAVORITE_OUTPUT_LANDS = "GET_FAVORITE_OUTPUT_LANDS";
export const GET_FAVORITE_OUTPUT_LANDS_SUCCESS =
  "GET_FAVORITE_OUTPUT_LANDS_SUCCESS";
export const GET_FAVORITE_OUTPUT_LANDS_FAILURE =
  "GET_FAVORITE_OUTPUT_LANDS_FAILURE";

export const SAVE_FAVORITE_OUTPUT_LAND = "SAVE_FAVORITE_OUTPUT_LAND";
export const SAVE_FAVORITE_OUTPUT_LAND_SUCCESS =
  "SAVE_FAVORITE_OUTPUT_LAND_SUCCESS";
export const SAVE_FAVORITE_OUTPUT_LAND_FAILURE =
  "SAVE_FAVORITE_OUTPUT_LAND_FAILURE";

export const SET_SHOW_MODAL = "SET_SHOW_MODAL";
export const SET_MODAL_MESSAGE = "SET_MODAL_MESSAGE";

export const GET_DISPLAYED_LAYERS_RESULTS = "GET_DISPLAYED_LAYERS_RESULTS";
export const GET_DISPLAYED_LAYERS_RESULTS_SUCCESS =
  "GET_DISPLAYED_LAYERS_RESULTS_SUCCESS";
export const GET_DISPLAYED_LAYERS_RESULTS_FAILURE =
  "GET_DISPLAYED_LAYERS_RESULTS_FAILURE";

export const SET_PROJECT_TYPE = "SET_PROJECT_TYPE";
export const SET_SUBREGION = "SET_SUBREGION";
export const SET_SEARCH_ENTITY_ADM_LEVEL_1 = "SET_SEARCH_ENTITY_ADM_LEVEL_1";
export const SET_SEARCH_ENTITY = "SET_SEARCH_ENTITY";
export const SET_PROJECT_SIZE_IN_MW = "SET_PROJECT_SIZE_IN_MW";
export const SET_ACRE = "SET_ACRE";

export const GET_SUBREGION_RESULTS = "GET_SUBREGION_RESULTS";
export const GET_SUBREGION_RESULTS_SUCCESS = "GET_SUBREGION_RESULTS_SUCCESS";
export const GET_SUBREGION_RESULTS_FAILURE = "GET_SUBREGION_RESULTS_FAILURE";

export const START_SEARCH = "START_SEARCH";
export const SEARCH_COMPLETED = "SEARCH_COMPLETED";
export const SEARCH_FAILED = "SEARCH_FAILED";
export const SEARCH_CONTINUE = "SEARCH_CONTINUE";
export const SAVE_SEARCH = "SAVE_SEARCH";
export const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS";

export const GET_SEARCH_ENTITIES = "GET_SEARCH_ENTITIES";
export const GET_SEARCH_ENTITIES_SUCCESS = "GET_SEARCH_ENTITIES_SUCCESS";
export const GET_SEARCH_ENTITIES_FAILURE = "GET_SEARCH_ENTITIES_FAILURE";

export const ADD_LAND_COLLECTION = "ADD_LAND_COLLECTION";
export const ADD_LAND_COLLECTION_SUCCESS = "ADD_LAND_COLLECTION_SUCCESS";
export const ADD_LAND_COLLECTION_FAILURE = "ADD_LAND_COLLECTION_FAILURE";

export const DELETE_LAND_COLLECTION_SUCCESS = "DELETE_LAND_COLLECTION_SUCCESS";
export const DELETE_LAND_COLLECTION = "DELETE_LAND_COLLECTION";
export const DELETE_LAND_COLLECTION_FAILURE = "DELETE_LAND_COLLECTION_FAILURE";

export const RENAME_COLLECTION_SUCCESS = "RENAME_COLLECTION_SUCCESS";
export const RENAME_COLLECTION_FAILURE = "RENAME_COLLECTION_FAILURE";
export const RENAME_COLLECTION = "RENAME_COLLECTION";

export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";
export const DELETE_COLLECTION_FAILURE = "DELETE_COLLECTION_FAILURE";
export const DELETE_COLLECTION = "DELETE_COLLECTION";

export const GET_COLLECTION_RESULTS = "GET_COLLECTION_RESULTS";
export const GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS";
export const GET_COLLECTION_FAILURE = "GET_COLLECTION_FAILURE";

export const GET_USER_COLLECTION_RESULTS = "GET_USER_COLLECTION_RESULTS";
export const GET_USER_COLLECTION_SUCCESS = "GET_USER_COLLECTION_SUCCESS";
export const GET_USER_COLLECTION_FAILURE = "GET_USER_COLLECTION_FAILURE";

export const ADD_COLLECTION = "ADD_COLLECTION";
export const ADD_COLLECTION_FAILURE = "ADD_COLLECTION_FAILURE";
export const ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS";

export const SET_SEARCH = "SET_SEARCH";
export const SET_TAB = "SET_TAB";

export const UPLOAD_FILE_ANALYZE_LAND = "UPLOAD_FILE_ANALYZE_LAND";
export const UPLOAD_FILE_ANALYZE_LAND_SUCCESS =
  "UPLOAD_FILE_ANALYZE_LAND_SUCCESS";
export const UPLOAD_FILE_ANALYZE_LAND_FAILURE =
  "UPLOAD_FILE_ANALYZE_LAND_FAILURE";
export const TOGGLE_ANALYZE_LAND_MODAL = "TOGGLE_ANALYZE_LAND_MODAL";
export const SET_ANALYZE_LAND_MODAL_OPEN = "SET_ANALYZE_LAND_MODAL_OPEN";
export const SET_ANALYZE_LAND_LOADING = "SET_ANALYZE_LAND_LOADING";
export const SET_ANALYZE_LAND_RESPONSE_URL = "SET__ANALYZE_LAND_RESPONSE_URL";
export const SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR =
  "SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR";
export const SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_ERROR =
  "SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_ERROR";
export const SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_SUCCESS =
  "SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_SUCCESS";
export const SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_LOADING =
  "SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_SUCCESS_LOADING";
export const ANALYZE_LAND_START_POLLING = "ANALYZE_LAND_START_POLLING";
export const ANALYZE_LAND_STOP_POLLING = "ANALYZE_LAND_STOP_POLLING";
export const ANALYZE_LAND_SET_ANALYSIS_RESULT =
  "ANALYZE_LAND_SET_ANALYSIS_RESULT";
export const SET_SHOW_ANALYSIS_RESULT_UI = "SET_SHOW_ANALYSIS_RESULT_UI";
export const SET_COMPLETED_LAND_ANALYSIS = "SET_COMPLETED_LAND_ANALYSIS";
export const ANALYZE_LAND_SET_ANALYSIS_ERROR =
  "ANALYZE_LAND_SET_ANALYSIS_ERROR";

export const FETCH_LAND_SEARCHES = "FETCH_LAND_SEARCHES";
export const SET_LAND_SEARCHES = "SET_LAND_SEARCHES";
