import { useDispatch, useSelector } from "react-redux";
import { getDisplayedLayersResults } from "../redux/actions/layersActions";
import { displayedLayersSelector } from "../redux/selectors/displayedLayers";
import { useState, useEffect } from "react";
import { useCallback } from "react";
import { addLayerSource, removeLayerSource } from "../redux/reducers/mapSlice";
import markdownIt from "markdown-it";

// Needed Constants --> Move to correspondent place.
const GEO_SERVER_DOMAIN_URL = process.env.REACT_APP_GEO_SERVER_DOMAIN_URL;
const GEO_SERVER_WORKSPACE_NAME =
  process.env.REACT_APP_GEO_SERVER_WORKSPACE_NAME;
const baseUrl = `${GEO_SERVER_DOMAIN_URL}/ows`;
const service = `SERVICE=WMS`;
const version = "VERSION=1.1.1";
const request = "REQUEST=GetMap";
const format = "FORMAT=image/png";
const transparent = "TRANSPARENT=true";
const styles = "STYLES=";
const srs = "SRS=EPSG:3857";
const width = "WIDTH=256";
const height = "HEIGHT=256";
const bbox = "BBOX={bbox-epsg-3857}";

export const useLayers = () => {
  const dispatch = useDispatch();
  const displayedLayers = useSelector(displayedLayersSelector);
  const getDisplayedLayers = useCallback(
    (data) => {
      const params = {
        token: data,
      };
      dispatch(getDisplayedLayersResults(params));
    },
    [dispatch]
  );
  const layersSource = useSelector((state) => state.map.layersSource);
  const [layers, setLayers] = useState({});

  const formatSourceAttributionsWithCredits = (credits) => {
    const md = new markdownIt();

    if (credits && credits.includes("](")) {
      const renderedCredits = md.renderInline(credits);

      // Add target="_blank" to any <a> tags
      return renderedCredits.replace(
        /<a\s+(?![^>]*target=["']_blank["'])/g,
        '<a target="_blank" '
      );
    }

    return credits || "";
  };

  const updateLayerSources = useCallback(
    (credit, active) => {
      if (active) {
        if (!layersSource.includes(credit)) {
          dispatch(addLayerSource(credit));
        }
      } else {
        const activeLayersWithSameSource = Object.values(layers).reduce(
          (count, category) => {
            const categoryCount = Object.values(category).filter(
              (layer) => layer.credits === credit && layer.active
            ).length;
            return count + categoryCount;
          },
          0
        );

        // Only remove the source if this is the last active layer using it
        // We check for 1 because this count includes the current layer that's being deactivated
        if (activeLayersWithSameSource <= 1) {
          dispatch(removeLayerSource(credit));
        }
      }
    },
    [dispatch, layers, layersSource]
  );

  useEffect(() => {
    if (displayedLayers) {
      const newLayers = displayedLayers.reduce((acc, layer) => {
        const layers = `LAYERS=${GEO_SERVER_WORKSPACE_NAME}:${layer.layer_table_name}`;
        const tileUrl = `${baseUrl}?${service}&${version}&${request}&${format}&${layers}&${transparent}&${styles}&${srs}&${width}&${height}&${bbox}`;
        const formattedAttribution = formatSourceAttributionsWithCredits(
          layer.credits
        );

        if (!acc[layer.layer_category]) {
          acc[layer.layer_category] = {};
        }

        const layerKey =
          layer.layer_name || layer.layer_subcategory || "unnamed_layer";

        acc[layer.layer_category][layerKey] = {
          active: layer.is_search_entity,
          tileUrl: tileUrl,
          credits: formattedAttribution,
        };

        // Add active layer's credits to layersSource on mount
        if (layer.is_search_entity && formattedAttribution) {
          updateLayerSources(formattedAttribution, true);
        }

        return acc;
      }, {});
      setLayers(newLayers);
    }
  }, [displayedLayers]);

  const changeLayer = (category, layerName, value) => {
    if (category in layers) {
      if (layerName in layers[category]) {
        const credits = layers[category][layerName]?.credits;

        //layers state
        setLayers((prevLayers) => ({
          ...prevLayers,
          [category]: {
            ...prevLayers[category],
            [layerName]: { ...prevLayers[category][layerName], active: value },
          },
        }));

        //layersSource state
        if (credits) {
          updateLayerSources(credits, value);
        }
      }
    }
  };

  return {
    layers,
    changeLayer,
    layersSource,
    getDisplayedLayers,
  };
};
