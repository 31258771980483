import React from "react";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import CreateModal from "./CreateModal";
import { Done } from "./Done";
import Text from "./Text";
const CreateCollectionModal = ({ setOpen, setAddOpen, addCollection }) => {
  const [val, setVal] = useState("");
  const [description, setDescription] = useState("");

  //const {saveAsFavoriteLand} = useFavoriteLandResults();

  const handleDone = () => {
    addCollection(val, description);
    setOpen(false);
    setAddOpen(true);
  };

  return (
    <CreateModal
      height={"45vh"}
      width={"450px"}
      setOpen={setOpen}
      overlayHeight={"100vh"}
      overlayWidth={"100vw"}
      customStyles={{
        top: "35vh",
        left: "50vw",
        boxShadow: 2,
      }}
      overlayColor="rgba(0, 0, 0, 0.5)"
      overlayTop={"-105px"}
      content={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: "24px",
          }}
        >
          <Typography
            sx={{ fontSize: "24px", fontWeight: 700, textAlign: "center" }}
          >
            {" "}
            Add collection{" "}
          </Typography>
          <Text label={"Collection name"} setVal={setVal} />
          <Text label={"Collection Description"} setVal={setDescription} />
          <Box>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
              What are collections?
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "14px" }}>
              {" "}
              Placeholder text
            </Typography>
          </Box>

          <Done val={val} handleDone={handleDone} />
        </Box>
      }
    />
  );
};

export default CreateCollectionModal;
