import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { StyledDrawer } from "../styles/TabbedSideBar.styles";
import { useTabbedSidebar } from "../new_hooks/useTabbedSidebar";
import { useSearchControls } from "../new_hooks/useSearchControls";
import LandSearchSideBar from "./LandSearchSideBar/LandSearchesSideBar";
import { TAB_NAMES } from "../utils/constants";

const TabbedSideBar = ({ landSearches, children, isHomePageRoute }) => {
  const tabs = [
    { index: 0, label: TAB_NAMES.SEARCHES },
    { index: 1, label: TAB_NAMES.RESULTS },
  ];
  const { isSidebarOpen, setActiveTab, activeTab } = useTabbedSidebar();
  const { search } = useSearchControls();
  const [resultsTabDisabled, setResultsTabDisabled] = useState(true);

  useEffect(() => {
    if (search) {
      setResultsTabDisabled(false);
    }
  }, [search]);

  const handleChange = (event, newIndex) => {
    setActiveTab(newIndex);
  };

  return (
    <StyledDrawer
      variant="persistent"
      anchor="left"
      open={isSidebarOpen}
      isHomePageRoute={isHomePageRoute}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          width: "100%",
          height: "100%",
          backgroundColor: "#ffffff",
          position: "sticky",
          top: 0,
          zIndex: 10000,
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="Side Bar"
          sx={{
            width: "540px",
            display: "flex",
            justifyContent: "center",
            "& .MuiTabs-indicator": {
              backgroundColor: "#52975C",
            },
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.index}
              label={tab.label}
              disabled={tab.label === TAB_NAMES.RESULTS && resultsTabDisabled}
              sx={{
                width: "50%",
                color: "rgba(0, 0, 0, 0.6)",
                "&.Mui-selected": {
                  color: "#52975C",
                },
              }}
            />
          ))}
        </Tabs>
        <Box
          sx={{
            flex: 1,
            padding: 2,
            height:
              tabs[activeTab].label === TAB_NAMES.SEARCHES
                ? "calc(100% - 112px)"
                : "calc(100% - 50px)",
            overflow: "hidden",
          }}
        >
          {tabs[activeTab].label === TAB_NAMES.SEARCHES ? (
            <LandSearchSideBar landSearches={landSearches.result} />
          ) : (
            children
          )}
        </Box>
      </Box>
    </StyledDrawer>
  );
};

export default TabbedSideBar;
