import {
  SET_ANALYZE_LAND_MODAL_OPEN,
  TOGGLE_ANALYZE_LAND_MODAL,
  UPLOAD_FILE_ANALYZE_LAND,
  UPLOAD_FILE_ANALYZE_LAND_SUCCESS,
  UPLOAD_FILE_ANALYZE_LAND_FAILURE,
  SET_ANALYZE_LAND_LOADING,
  SET_ANALYZE_LAND_RESPONSE_URL,
  SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR,
  SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_ERROR,
  SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_SUCCESS,
  SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_LOADING,
  ANALYZE_LAND_START_POLLING,
  ANALYZE_LAND_STOP_POLLING,
  ANALYZE_LAND_SET_ANALYSIS_RESULT,
  SET_SHOW_ANALYSIS_RESULT_UI,
  SET_COMPLETED_LAND_ANALYSIS,
  ANALYZE_LAND_SET_ANALYSIS_ERROR,
} from "./types";

export const uploadFileAnalyzeLand = (payload) => {
  return {
    type: UPLOAD_FILE_ANALYZE_LAND,
    payload,
  };
};

export const uploadFileAnalyzeLandSuccess = (response) => ({
  type: UPLOAD_FILE_ANALYZE_LAND_SUCCESS,
  payload: response,
});

export const uploadFileAnalyzeLandFailure = (error) => ({
  type: UPLOAD_FILE_ANALYZE_LAND_FAILURE,
  payload: error,
});

export const toggleAnalyzeLandModal = () => ({
  type: TOGGLE_ANALYZE_LAND_MODAL,
});

export const setAnalyzeLandOpenModal = (shouldOpen) => ({
  type: SET_ANALYZE_LAND_MODAL_OPEN,
  payload: shouldOpen,
});

export const setAnalyzeLandLoading = (isLoading) => ({
  type: SET_ANALYZE_LAND_LOADING,
  payload: isLoading,
});

export const setAnalyzeLandResponseUrl = (url) => ({
  type: SET_ANALYZE_LAND_RESPONSE_URL,
  payload: url,
});

export const setLandAnalysisProcessOrchestrator = ({ token, url }) => ({
  type: SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR,
  payload: { token, url },
});

export const setLandAnalysisProcessOrchestratorSuccess = (data) => ({
  type: SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_SUCCESS,
  payload: data,
});

export const setLandAnalysisProcessOrchestratorError = (error) => ({
  type: SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_ERROR,
  payload: error,
});

export const setLandAnalysisProcessOrchestratorIsLoading = (isLoading) => ({
  type: SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_LOADING,
  payload: isLoading,
});

export const startPolling = (statusQueryGetUri) => ({
  type: ANALYZE_LAND_START_POLLING,
  payload: { statusQueryGetUri },
});

export const stopPolling = () => ({
  type: ANALYZE_LAND_STOP_POLLING,
});

export const setAnalysisResult = (result) => ({
  type: ANALYZE_LAND_SET_ANALYSIS_RESULT,
  payload: result,
});

export const handleShowAnalyzeLandResultsUI = (show) => ({
  type: SET_SHOW_ANALYSIS_RESULT_UI,
  payload: show,
});

export const setCompletedLandAnalysis = (completed) => ({
  type: SET_COMPLETED_LAND_ANALYSIS,
  payload: completed,
});

export const setAnalysisError = (error) => ({
  type: ANALYZE_LAND_SET_ANALYSIS_ERROR,
  payload: error,
});
