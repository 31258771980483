import { FileUploadOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { motion } from "framer-motion";
import styled from "styled-components";

export const AnalyzeLandBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  gap: 24px;
  margin-top: -24px;
  width: 100%;
`;

export const AnalyzeLandModalTitle = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 24px;
    color: #212121;
    text-align: center;
  }
`;

export const StyledFileUploadOutlined = styled(FileUploadOutlined)`
  && {
    width: 100px;
    height: 100px;
    color: #52975c;
  }
`;

export const StyledDragAndDropBox = styled(Box)`
  background-color: #eeeeee;
  border: 1px dashed #a0aec0;
  width: 100%;
  height: 50%;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0rem;
  gap: 1rem;

  p {
    font-size: 12px;
    text-align: center;
  }

  .select-file {
    color: #52975c;
    text-decoration: underline;
  }

  input {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
`;

export const StyledMotionDiv = styled(motion.div)`
  width: 100%;
`;

export const StyledUploadButton = styled(Button)`
  height: 48px !important;

  && {
    background-color: ${({ disabled }) => (disabled ? "#95C09B" : "#52975C")};
    color: ${({ disabled }) => (disabled ? "#95C09B" : "#FFFFFF")};
    &:hover {
      background-color: ${({ disabled }) => (disabled ? "#95C09B" : "#3e7a4d")};
    }
    width: 40%;
    //text-transform: capitalize;
  }
`;
