import React, { useEffect } from "react";
import { Box, ToggleButtonGroup, ToggleButton } from "@mui/material";
import QuestionModal from "./QuestionModal";
import { useState } from "react";
import BannerBlackLogo from "../images/new-black-logo.png";
import SearchBar from "./SearchBar";
import UsernameButton from "../components/UsernameButton";
import useUser from "../hooks/userHook";
import AnalyzeLandModal from "./AnalyzeLand/AnalyzeLandModal";
import { useAnalyzeLand } from "../new_hooks/useAnalyzeLand";
import { isObjEmpty } from "../utils/functions";

const HeaderBar = ({
  tab,
  changeTab,
  search,
  setToCollection,
  isSearching,
  setViewState,
}) => {
  const [clickedButton, setClickedButton] = useState("project");

  const {
    setShowAnalyzeLandResultsUI,
    analysisResultPoll,
    isAnalyzeLandModalOpen,
    analyze,
    setAnalyze,
  } = useAnalyzeLand({ clickedButton, setClickedButton });
  const [help, setHelp] = useState(false);
  const { userInfo, onLogout } = useUser();
  const userName = (userInfo && (userInfo.name || userInfo.given_name)) || "";

  //Search stuff should be done in redux probably!! Honestly need access to it here!

  function refreshPage() {
    window.location.reload();
  }

  //Side effects , where I only want to losten to this local state, not the hook where it spreads across multiple components
  useEffect(() => {
    if (clickedButton === "analyze") {
      if (
        isObjEmpty(analysisResultPoll?.result) &&
        analysisResultPoll?.status !== "error"
      ) {
        setAnalyze(true);
      } else if (isAnalyzeLandModalOpen) {
        setShowAnalyzeLandResultsUI(false);
      } else {
        setShowAnalyzeLandResultsUI(true);
      }
    } else if (clickedButton === "project") {
      setShowAnalyzeLandResultsUI(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedButton, analysisResultPoll]);

  const handleClickForError = () => {
    const isError = analysisResultPoll?.status === "error";
    const isEmpty = isObjEmpty(analysisResultPoll);

    if (isError || isEmpty) {
      setClickedButton("idle");
      setTimeout(() => {
        setClickedButton("analyze");
      }, 1);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        paddingX: "24px",
        paddingY: "16px",
        width: "100%",
        position: "relative",
      }}
    >
      {help && <QuestionModal setHelp={setHelp} />}
      {analyze && <AnalyzeLandModal setOpen={setAnalyze} />}

      <Box sx={{ position: "absolute", top: "16px", left: "24px" }}>
        <img
          src={BannerBlackLogo}
          width={"115px"}
          style={{ cursor: "pointer" }}
          onClick={refreshPage}
          alt="Banner Logo"
        />
      </Box>
      {search ? (
        <SearchBar
          search={search}
          isSearching={isSearching}
          setToCollection={setToCollection}
          setViewState={setViewState}
        />
      ) : (
        <ToggleButtonGroup
          value={tab}
          exclusive
          onChange={changeTab}
          sx={{
            height: "40px",
            display: "flex",
            justifyContent: "center",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <ToggleButton
            variant="out"
            value="CreateProject"
            onClick={(e) => {
              e.preventDefault();
              setClickedButton("project");
            }}
            selected={clickedButton === "project"}
          >
            {" "}
            Create Project{" "}
          </ToggleButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ToggleButton
              variant="out"
              value="AnalyzeLand"
              onClick={() => {
                setClickedButton("analyze");
                handleClickForError();
              }}
              selected={clickedButton === "analyze"}
            >
              Analyze Land
            </ToggleButton>
          </Box>
        </ToggleButtonGroup>
      )}

      <Box>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            position: "absolute",
            right: "24px",
            top: "16px",
            cursor: "pointer",
          }}
        >
          {/*<HelpOutlineIcon
            sx={{ cursor: "pointer" }}
            onClick={() => setHelp(!help)}
          />*/}
          <UsernameButton userName={userName} onClick={onLogout} />
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderBar;
