import { Accordion, Box, Button, Drawer, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledDrawer = styled(Drawer)`
  && {
    width: 580px;
    flex-shrink: 0px;
    top: 200px;
    & .MuiDrawer-paper {
      width: 580px;
      box-sizing: border-box;
      height: calc(100% - 166px);
      border: none;
      padding: 1rem;
    }
    display: flex;
    height: 100%;
    z-index: 6001 !important;

    .general-info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 0.5rem;
      p {
        font-size: 14px !important;
      }
      .title {
        font-weight: 600;
        font-size: 1rem !important;
      }
    }
  }
`;

export const SidebarHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  .header-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
`;

export const SidebarHeaderTitle = styled(Typography)`
  && {
    color: #47784e !important;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const ValueStyled = styled(Typography)`
  && {
    font-size: 14px;
    text-transform: capitalize;
  }
`;

export const AcordeonsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const StyledAccordion = styled(Accordion)`
  && {
    box-shadow: none;
    &::before {
      display: none;
    }
    h6 {
      font-size: 16px;
      color: #47784e;
      text-decoration: underline;
      padding: 0px;
      font-weight: 400;
    }
    .MuiAccordionDetails-root,
    .MuiAccordionSummary-root {
      padding: 0px;
    }
  }
`;

export const StyledReportButton = styled(Button)`
  && {
    height: 32px;
    background-color: #ffffff;
    border: 1px solid #52975c;
    color: #52975c;
    display: flex;
    gap: 6px;

    &:hover {
      color: #818992;
      border: 1px solid #99c29f;
      background-color: #ffffff;
    }
  }
`;
export const ShareButton = styled(Button)`
  && {
    height: 32px;
    color: #52975c;
    border-color: #52975c;
    &:hover {
      color: #818992;
      border-color: #99c29f;
      background-color: #ffffff;
    }
  }
`;

export const UploadButton = styled(Button)`
  && {
    height: 48px;
    background-color: #52975c;
    color: #ffffff;
    display: flex;
    gap: 6px;
    margin-bottom: 1rem;
    p {
      font-weight: 800 !important;
    }
    &:hover {
      background-color: #99c29f;
    }
  }
`;
