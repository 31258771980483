import { Box } from "@mui/material";
import styled from "styled-components";

export const StyledFilePreview = styled(Box)`
  border: 1px solid #c3ccd7;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem;

  .file-content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    .file-name {
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }

    .file-size {
      font-size: 11px;
      color: #818992;
    }
  }

  .delete-button {
    cursor: pointer;
  }
`;
