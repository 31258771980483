import {
  SET_ANALYZE_LAND_MODAL_OPEN,
  TOGGLE_ANALYZE_LAND_MODAL,
  UPLOAD_FILE_ANALYZE_LAND,
  UPLOAD_FILE_ANALYZE_LAND_SUCCESS,
  UPLOAD_FILE_ANALYZE_LAND_FAILURE,
  SET_ANALYZE_LAND_LOADING,
  SET_ANALYZE_LAND_RESPONSE_URL,
  SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR,
  SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_ERROR,
  SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_SUCCESS,
  SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_LOADING,
  ANALYZE_LAND_START_POLLING,
  ANALYZE_LAND_STOP_POLLING,
  ANALYZE_LAND_SET_ANALYSIS_RESULT,
  SET_SHOW_ANALYSIS_RESULT_UI,
  SET_COMPLETED_LAND_ANALYSIS,
  ANALYZE_LAND_SET_ANALYSIS_ERROR,
} from "../actions/types";

const initialState = {
  uploadRequest: null,
  responseUrl: null,
  uploadIsLoading: false,
  uploadError: false,
  isAnalyzeLandOpen: false,
  landAnalysisProcessOrchestrator: {
    id: null,
    statusQueryGetUri: null,
    sendEventPostUri: null,
    terminatePostUri: null,
    rewindPostUri: null,
    purgeHistoryDeleteUri: null,
    restartPostUri: null,
    suspendPostUri: null,
    resumePostUri: null,
  },
  landAnalysisOrchestratorError: false,
  landAnalysisProcessOrchestratorRequest: null,
  landAnalysisIsLoading: false,
  completedAnalysis: false,
  analysisResult: null,
  isPolling: false,
  showAnalyzeLandResultsUI: false,
  analysisError: null,
};

function analyzeLandReducer(state = initialState, action) {
  switch (action.type) {
    case "persist/REHYDRATE":
      return {
        ...initialState,
        ...state,
        ...action.payload?.analyzeLand, // Rehydrate with persisted fix!!
      };
    case UPLOAD_FILE_ANALYZE_LAND:
      return {
        ...state,
        uploadRequest: action.payload,
        uploadIsLoading: true,
      };
    case UPLOAD_FILE_ANALYZE_LAND_SUCCESS:
      return {
        ...state,
        responseUrl: action.payload,
        uploadIsLoading: false,
      };
    case UPLOAD_FILE_ANALYZE_LAND_FAILURE:
      return {
        ...state,
        uploadIsLoading: false,
        uploadError: true,
      };
    case TOGGLE_ANALYZE_LAND_MODAL:
      return {
        ...state,
        isAnalyzeLandOpen: !state.isAnalyzeLandOpen,
      };
    case SET_ANALYZE_LAND_MODAL_OPEN:
      return {
        ...state,
        isAnalyzeLandOpen: action.payload,
      };
    case SET_ANALYZE_LAND_LOADING:
      return {
        ...state,
        uploadIsLoading: action.payload,
      };
    case SET_ANALYZE_LAND_RESPONSE_URL:
      return {
        ...state,
        responseUrl: action.payload,
      };
    case SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_SUCCESS:
      return {
        ...state,
        landAnalysisProcessOrchestrator: action.payload,
        landAnalysisIsLoading: false,
        landAnalysisOrchestratorError: false,
      };
    case SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_ERROR:
      return {
        ...state,
        landAnalysisOrchestratorError: true,
        landAnalysisIsLoading: false,
      };
    case SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR:
      return {
        ...state,
        landAnalysisProcessOrchestratorRequest: action.payload,
        landAnalysisIsLoading: true,
      };
    case SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR_LOADING:
      return {
        ...state,
        landAnalysisIsLoading: action.payload,
      };
    case ANALYZE_LAND_START_POLLING:
      return {
        ...state,
        isPolling: true,
        completedAnalysis: false,
      };
    case ANALYZE_LAND_STOP_POLLING:
      return {
        ...state,
        isPolling: false,
      };
    case ANALYZE_LAND_SET_ANALYSIS_RESULT:
      return {
        ...state,
        completedAnalysis: true,
        analysisResult: action.payload,
        analysisError: null,
      };
    case SET_SHOW_ANALYSIS_RESULT_UI:
      return {
        ...state,
        showAnalyzeLandResultsUI: action.payload,
      };
    case SET_COMPLETED_LAND_ANALYSIS:
      return {
        ...state,
        completedAnalysis: action.payload,
      };
    case ANALYZE_LAND_SET_ANALYSIS_ERROR:
      return {
        ...state,
        analysisError: action.payload,
      };
    default:
      return state;
  }
}

export default analyzeLandReducer;
