import { takeLatest } from "redux-saga/effects";
import {
  ANALYZE_LAND_START_POLLING,
  SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR,
  UPLOAD_FILE_ANALYZE_LAND,
} from "../../actions/types";
import {
  handleLandAnalysisProcessOrchestrator,
  handleUploadFile,
  pollStatus,
} from "./analyzeLandSagas";

export default function* analyzeLandSaga() {
  yield takeLatest(UPLOAD_FILE_ANALYZE_LAND, handleUploadFile);
  yield takeLatest(
    SET_ANALYZE_LAND_PROCESS_ORCHESTRATOR,
    handleLandAnalysisProcessOrchestrator
  );
  yield takeLatest(ANALYZE_LAND_START_POLLING, pollStatus);
}
