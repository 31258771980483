import { Box, Typography } from "@mui/material";

/**
 * Checks if an array of objects has non-null geometries in their 'geom' field.
 * @param {Array} dataArray - The array of objects to check.
 * @returns {boolean} - Returns true if all objects have non-null geometries, otherwise false.
 */
export const hasGeometries = (dataArray) => {
  if (!Array.isArray(dataArray) || dataArray.length === 0) {
    return false;
  }

  for (let item of dataArray) {
    if (!item.geom || Object.keys(item.geom).length === 0) {
      return false;
    }
  }

  return true;
};

// Function to get a condition based on ranking
export const getConditionFromRanking = (ranking) => {
  if (ranking >= 1 && ranking <= 3) {
    return "Excellent";
  } else if (ranking >= 4 && ranking <= 8) {
    return "Medium";
  } else if (ranking > 8) {
    return "Low";
  } else {
    return "Unknown"; // or any default condition you prefer
  }
};

function isFloat(value) {
  return Number.isFinite(value) && !Number.isInteger(value);
}

// Function to format the value based on whether it is an integer or a float
const formatValue = (value) => {
  if (typeof value === "number") {
    // Check if it's an integer by comparing it to the rounded version of itself
    if (isFloat(value)) {
      return value.toFixed(2); // Apply toFixed(2) for floats
    } else {
      return value; // Return as is for integers
    }
  }
  return value; // Return the value directly if it's not a number
};

const isValidObject = (obj) => obj !== null && obj !== undefined;

export const getLandDetails = (landData) => {
  // Check if landData is valid
  if (!landData || Object.keys(landData).length === 0) {
    return {};
  }

  const keyToTitle = {
    area_in_m2: "Area (m²)",
    numb_parcels: "Number of Parcels",
    number_of_available_land_owners_identification:
      "Number of available Land Owners Ids",
    crop_categories: "Crop Categories",
    land_owner_names: "Land Owner Names",
    parcel_ids: "Parcels identification number/s",
    // Add other key mappings here
  };

  return (
    Object.entries(landData)
      // Generate land details only for keys present in keyToTitle
      .filter(([key, value]) => keyToTitle.hasOwnProperty(key) && value)
      .map(([key, value]) => {
        const title = keyToTitle[key]; // Get the human-readable title
        const formattedValue =
          key === "area_in_m2"
            ? `${formatValue(value)} m²` //  (${Math.floor(value / 1000)} dunams)
            : formatValue(value); // Format the value based on its type
        return (
          <Box>
            {/*<img src={LandIcon} alt={`${title} icon`} />*/}
            <Typography
              sx={{ fontSize: "12px", fontWeight: 500, display: "inline" }}
            >
              {title} :{" "}
            </Typography>
            <Typography
              sx={{ fontSize: "12px", fontWeight: 400, display: "inline" }}
            >
              {formattedValue}
            </Typography>
          </Box>
        );
      })
  );
};

export const getAcRuleDetails = (landData) => {
  return landData?.ac_rules_data?.map((ac_rule) => {
    return (
      <Box key={ac_rule.ac_rule_id}>
        {/* If you have specific icons for each rule, you can add them here */}
        <Typography
          sx={{ fontSize: "12px", fontWeight: 500, display: "inline" }}
        >
          {ac_rule.ac_rule_criteria_name}:{" "}
        </Typography>
        <Typography
          sx={{ fontSize: "12px", fontWeight: 400, display: "inline" }}
        >
          {ac_rule.ac_rule_value === -9999
            ? "-"
            : typeof ac_rule.ac_rule_value === "number" &&
              !isNaN(ac_rule.ac_rule_value)
            ? `${ac_rule.ac_rule_value.toFixed(2)}${
                ac_rule.data_layer_info_unit ? ac_rule.data_layer_info_unit : ""
              }`
            : "-"}
        </Typography>
      </Box>
    );
  });
};

export const getEcRuleDetails = (landData) => {
  return landData?.ec_rules_data?.map((ec_rule) => {
    return (
      <Box>
        <Typography
          sx={{ fontSize: "12px", fontWeight: 500, display: "inline" }}
        >
          {ec_rule.ec_rule_sub_criteria_name ? (
            <span>
              {ec_rule.ec_rule_criteria_name} (
              {ec_rule.ec_rule_sub_criteria_name})
            </span>
          ) : (
            <span>{ec_rule.ec_rule_criteria_name}</span>
          )}{" "}
          :{" "}
        </Typography>
        <Typography
          sx={{ fontSize: "12px", fontWeight: 400, display: "inline" }}
        >
          {ec_rule.ec_rule_value === -9999
            ? "-"
            : `${ec_rule.ec_rule_value.toFixed(2)}${
                ec_rule.data_layer_info_unit
              }`}
        </Typography>
      </Box>
    );
  });
};

export const getCoordinatesDetails = (latitude, longitude) => {
  return (
    <div className="row">
      <div className="col">
        <label>
          <span>
            <u>Latitude</u>: {latitude}
          </span>
        </label>
      </div>
      <div className="col">
        <label>
          <span>
            <u>Longitude</u>: {longitude}
          </span>
        </label>
      </div>
    </div>
  );
};

export const getScoreAndRankingValues = (landData) => {
  if (!isValidObject(landData)) {
    return [];
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        <Typography
          sx={{ fontSize: "12px", fontWeight: 500, display: "inline" }}
        >
          Ranking:{" "}
        </Typography>
        <Typography
          sx={{ fontSize: "12px", fontWeight: 400, display: "inline" }}
        >
          {" "}
          {landData["ranking_numerator_denominator"]}{" "}
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{ fontSize: "12px", fontWeight: 500, display: "inline" }}
        >
          Score:{" "}
        </Typography>
        <Typography
          sx={{ fontSize: "12px", fontWeight: 400, display: "inline" }}
        >
          {" "}
          {`${formatValue(landData["ac_score"])}/100`}
        </Typography>
      </Box>
    </Box>
  );
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
    hour12: false,
  };

  const formattedDate = date.toLocaleString("en-GB", options);

  const [datePart, timePart] = formattedDate.split(", ");
  return `${datePart.replace(/\//g, "/")} ${timePart} UTC`;
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const isObjEmpty = (obj) => !obj || Object.keys(obj).length === 0;

export const formatErrorMessageLandAnalysisPoll = (errorString) => {
  const match = errorString.match(/:\s(.+)$/);
  return match ? match[1] : "An unknown error occurred.";
};
