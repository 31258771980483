import styled from "styled-components";
import { Drawer } from "@mui/material";

export const StyledDrawer = styled(Drawer)`
  width: 540px;
  flex-shrink: 0;

  & .MuiDrawer-paper {
    width: 580px;
    box-sizing: border-box;
    height: ${({ isHomePageRoute }) =>
      isHomePageRoute ? "calc(100% - 166px)" : "calc(100% - 105px)"};
    border: none;
    display: flex;
    flex-direction: column;
  }
`;
