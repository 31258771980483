import axios from "axios";
import {
  uploadFileAnalyzeLandFailure,
  uploadFileAnalyzeLandSuccess,
  setAnalyzeLandLoading,
  setLandAnalysisProcessOrchestratorError,
  setLandAnalysisProcessOrchestratorSuccess,
  stopPolling,
  setAnalysisResult,
  setAnalysisError,
} from "../../actions/analyzeLand";
import { ANALYZE_LAND_STOP_POLLING } from "../../actions/types";

import * as api from "../../api";
import { call, delay, put, race, take } from "redux-saga/effects";
import { formatErrorMessageLandAnalysisPoll } from "../../../utils/functions";

export function* handleUploadFile(action) {
  const { token, file } = action.payload;
  try {
    yield put(setAnalyzeLandLoading(true));
    const response = yield call(api.uploadFile, { token, file });
    yield put(uploadFileAnalyzeLandSuccess(response.url));
  } catch (error) {
    yield put(uploadFileAnalyzeLandFailure(error.message));
  } finally {
    yield put(setAnalyzeLandLoading(false));
  }
}

export function* handleLandAnalysisProcessOrchestrator(action) {
  const { token, url } = action.payload;
  try {
    const response = yield call(api.landAnalysisProcessOrchestrator, {
      token,
      landParcelUrl: url,
    });
    yield put(setLandAnalysisProcessOrchestratorSuccess(response));
  } catch (error) {
    yield put(setLandAnalysisProcessOrchestratorError(error.message));
  }
}

const POLLING_INTERVAL = 5000; // Poll every 5 seconds

function fetchOrchestratorStatus(url) {
  return axios.get(url);
}

export function* pollStatus(action) {
  const { statusQueryGetUri } = action.payload;

  try {
    while (true) {
      const { response, cancel } = yield race({
        response: call(fetchOrchestratorStatus, statusQueryGetUri),
        cancel: take(ANALYZE_LAND_STOP_POLLING),
      });

      if (cancel) {
        yield put(stopPolling());
        break;
      }
      if (!response || !response.data) continue;

      const { runtimeStatus, output } = response.data;

      if (runtimeStatus === "Completed") {
        yield put(setAnalysisResult(output));
        yield put(stopPolling());
        break;
      }

      if (runtimeStatus === "Failed") {
        const formattedOutput = formatErrorMessageLandAnalysisPoll(output);
        yield put(setAnalysisError(formattedOutput));
        yield put(stopPolling());
        break;
      }

      yield delay(POLLING_INTERVAL);
    }
  } catch (error) {
    console.error("Error polling orchestrator status:", error);
    yield put(stopPolling());
  }
}
