import { Box, Drawer, Pagination, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledDrawer = styled(Drawer)`
  width: 400px;
  flex-shrink: 0px;
  & .MuiDrawer-paper {
    width: 400px;
    box-sizing: border-box;
    height: calc(100% - 166px);
    border: none;
  }
  display: flex;
  height: 100%;
`;

export const SidebarHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  margin-bottom: 8px;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const SidebarHeaderTitle = styled(Typography)`
  color: #52975c !important;
  font-size: 16px;
`;

export const StyledPagination = styled(Pagination)`
  & .MuiPaginationItem-root {
    border: 1px solid #52975c;
    color: #52975c;
  }
  & .Mui-selected {
    background-color: #52975c !important;
    color: #ffffff;
    &:hover {
      background-color: #52975c;
    }
  }
  & .MuiPaginationItem-ellipsis {
    border: none;
  }
`;

export const PaginationWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  position: absolute;
  bottom: 0;
  z-index: 10px;
  background-color: #ffffff;
  width: 96%;
  flex-shrink: 0;
`;

export const CardsContainer = styled(Box)`
  overflow-y: auto;
  padding: 16px;
  height: 100%;
`;
export const SidebarContent = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
