import React from "react";
import CreateModal from "./CreateModal";
import { Box, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShareIcon from "@mui/icons-material/Share";
import { useMap } from "../new_hooks/useMap";
import { useState, useEffect } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import tokml from "tokml";
const Report = ({
  setOpen,
  data,
  customStyles = {},
  overlayColor = null,
  overlayWidth,
  overlayHeight,
}) => {
  const [showBanner, setShowBanner] = useState(false);

  const { convertLands } = useMap();

  useEffect(() => {
    let timer;
    if (showBanner) {
      timer = setTimeout(() => {
        setShowBanner(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showBanner]);

  const generateKMZ = () => {
    if (!data || !data.geom) {
      console.error("No geometry data available");
      return;
    }

    // Convert from coordinates 3857 to 4326
    const convertedData = convertLands([data])[0];

    // Add desired properties
    const geoJsonFeature = {
      type: "Feature",
      geometry: convertedData.geom,
      properties: {
        name: `Site ${convertedData.id || "Unknown"}`,
        output_land_created_date:
          convertedData.output_land_created_date || "N/A",
        ranking_numerator_denominator:
          convertedData.ranking_numerator_denominator || "N/A",
        area_in_m2: convertedData.area_in_m2 || "N/A",
        ac_score: convertedData.ac_score || "N/A",
      },
    };

    const geoJsonObject = {
      type: "FeatureCollection",
      features: [geoJsonFeature],
    };

    // Convertir GeoJSON a KML
    const kmlContent = tokml(geoJsonObject, {
      name: "name",
      description: "description",
    });

    // Crear archivo KMZ
    const zip = new JSZip();
    zip.file("doc.kml", kmlContent);
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "site_report.kmz");
    });
  };

  const handleClick = () => {
    setShowBanner(true);
  };

  const icon = (text, ico, func) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
          width: "110px",
        }}
      >
        <Box
          sx={{
            padding: "24px",
            borderRadius: "1000px",
            backgroundColor: "#9D9D9D24",
            height: "72px",
            width: "72px",
            cursor: "pointer",
          }}
          onClick={func}
        >
          {ico}
        </Box>
        <Typography
          sx={{ fontSize: "14px", fontWeight: 500, cursor: "pointer" }}
          onClick={func}
        >
          {text}
        </Typography>
      </Box>
    );
  };

  return (
    <Box>
      {showBanner && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            backgroundColor: "#47784E",
            color: "white",
            textAlign: "center",
            padding: "10px",
            zIndex: 1000,
          }}
        >
          Coming Soon!
        </Box>
      )}

      <CreateModal
        height={null}
        width={null}
        setOpen={setOpen}
        customStyles={customStyles}
        overlayColor={overlayColor}
        overlayWidth={overlayWidth}
        overlayHeight={overlayHeight}
        overlayTop={"-105px"}
        content={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "46px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
                Report Generated
              </Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                Share it with:
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "16px" }}>
              {icon("Download report", <DownloadIcon />, generateKMZ)}
              {icon("E-mail", <AlternateEmailIcon />, handleClick)}
              {icon("Telegram", <TelegramIcon />, handleClick)}
              {icon("Whatapp", <WhatsAppIcon />, handleClick)}
              {icon("More", <ShareIcon />, handleClick)}
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default Report;
