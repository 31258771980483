import { Box, Typography } from "@mui/material";
import { StyledFilePreview } from "../../styles/AnalyzeLand/FilePreview.style";
import { DeleteOutline, UploadFile } from "@mui/icons-material";

const FilePreview = ({ fileName, fileSize, onDeleteFile }) => {
  const fileSizeKB = (fileSize / 1024).toFixed(2);
  return (
    <StyledFilePreview>
      <Box className="file-content">
        <UploadFile />
        <Box>
          <Typography className="file-name">{fileName}</Typography>
          <Typography className="file-size">{fileSizeKB} KB</Typography>
        </Box>
      </Box>

      <DeleteOutline onClick={onDeleteFile} className="delete-button" />
    </StyledFilePreview>
  );
};

export default FilePreview;
