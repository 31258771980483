import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mapStyle: "base",
  layersSource: [],
};

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setMapStyle: (state, action) => {
      state.mapStyle = action.payload;
    },
    addLayerSource: (state, action) => {
      if (!state.layersSource.includes(action.payload)) {
        state.layersSource.push(action.payload);
      }
    },
    removeLayerSource: (state, action) => {
      state.layersSource = state.layersSource.filter(
        (source) => source !== action.payload
      );
    },
  },
});

export const { setMapStyle, addLayerSource, removeLayerSource } =
  mapSlice.actions;
export default mapSlice.reducer;
