import { Box } from "@mui/material";
import styled from "styled-components";

export const BoxContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 1rem;
  flex-direction: column;
  text-align: center;

  p {
    font-size: 1.5rem;
    font-weight: 700;
  }
`;
