import {
  SET_PROJECT_TYPE,
  SET_SUBREGION,
  SET_SEARCH_ENTITY_ADM_LEVEL_1,
  SET_SEARCH_ENTITY,
  SET_PROJECT_SIZE_IN_MW,
  SET_ACRE,
  START_SEARCH,
  SEARCH_COMPLETED,
  SEARCH_FAILED,
  SEARCH_CONTINUE,
  SAVE_SEARCH,
  SET_SEARCH_PARAMS,
  GET_SEARCH_ENTITIES,
  GET_SEARCH_ENTITIES_SUCCESS,
  GET_SEARCH_ENTITIES_FAILURE,
  SET_SEARCH,
  SET_TAB,
} from "../actions/types";

const searchReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SEARCH_ENTITIES:
      return {
        ...state,
        searchEntitiesLoading: true, //TODO: Implement it
        searchEntitiesError: null, //TODO: Implement it
      };
    case GET_SEARCH_ENTITIES_SUCCESS:
      return {
        ...state,
        searchEntitiesLoading: false,
        searchEntities: {
          search_entities: action.payload.data.search_entities,
          adm_level_entities: action.payload.data.adm_level_entities,
        },
        inputs: action.payload.inputs,
      };
    case GET_SEARCH_ENTITIES_FAILURE:
      return {
        ...state,
        searchEntitiesLoading: false,
        searchEntitiesError: action.payload,
      };

    case START_SEARCH:
      return { ...state, loading: true, error: null };
    case SEARCH_COMPLETED:
      return {
        ...state,
        loading: false,
        result: action.payload,
        statusUrl: null, // Remove status Url
      };
    case SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        statusUrl: null, // Remove status Url
      };
    case SEARCH_CONTINUE:
      return { ...state, loading: true }; //should i do action.payload??
    case SAVE_SEARCH:
      return {
        ...state,
        searchId: action.payload.searchId,
        statusUrl: action.payload.statusUrl,
      };

    case SET_PROJECT_SIZE_IN_MW:
      return {
        ...state,
        projectSizeInMW: action.payload,
      };
    case SET_ACRE:
      return {
        ...state,
        acre: action.payload,
      };
    case SET_PROJECT_TYPE:
      return {
        ...state,
        projectType: action.payload,
      };
    case SET_SUBREGION:
      return {
        ...state,
        subRegion: action.payload,
      };
    case SET_SEARCH_ENTITY_ADM_LEVEL_1:
      return {
        ...state,
        searchEntityAdmLevel1: action.payload,
      };
    case SET_SEARCH_ENTITY:
      return {
        ...state,
        searchEntity: action.payload,
      };

    case SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.payload,
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    default:
      return state;
  }
};

export default searchReducer;
