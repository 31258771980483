export const STATUS_CODES = {
  UNAUTHORIZED: 401,
  // Add more status codes here...
};

export const PROJECT_SIZES = {
  CPV: {
    MW: [1, 2, 3, 4, 5],
    ACRES: [5, 11, 17, 23, 28],
  },
  SPV: {
    MW: [0, 25, 50, 100, 150, 200],
    ACRES: [0, 140, 284, 568, 852, 1136],
  },
};

export const PROJECT_SIZES_UNIT_NAMES = {
  MW: "mw",
  ACRES: "acres",
};

export const TAB_NAMES = {
  SEARCHES: "SEARCHES",
  RESULTS: "RESULTS",
};
