import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSearch } from "../new_hooks/useSearch";
import SelectSearch from "./SelectSearch";
import LoadingResults from "./LoadingResults";
import { ROUTES } from "../routes/constants";
import { StyledSpinner } from "../styles/Spinner.styled";
import ProjectSize from "./ProjectSize";
import AutocompleteSearch from "./SelectSearchWithAutocomplete";
import { useTabbedSidebar } from "../new_hooks/useTabbedSidebar";

const SearchBar = ({ search, isSearching, setToCollection, setViewState }) => {
  const [showModal, setShowModal] = useState(true); //Will get rid of when isLoading works?
  const [openSize, setOpenSize] = useState(false);
  const navigate = useNavigate();
  const {
    searchEntityAdmLevel1Value,
    searchEntity = null,
    selectedSubregion,
    projectType,
    projectSizeInMW = 1, //TODO: Check if correct add it by default.
    sProjectSizeInMW,
    sAcre,
    acre,
    sSearchEntity,
    sProjectType,
    sSubregion,
    sSearchEntityAdmLevel1,
    optionsSearchEntitiesAdmLevel1,
    optionsSearchEntities,
    searchEntitiesProperties,
    optionsTechnology,
    optionsSubregion,
    onSubmit,
    isLoadingOutputLands,
    loadSearch,
    searchEntitiesLoader,
    isLoadingSearchInputs,
    projectSizeConfig,
    sizeUnit,
    toggleSizeUnit,
    handleAutocompleteEntity,
    admLevelEntities,
  } = useSearch(setViewState);
  const {
    setActiveTab,
    closeSidebar,
    openSidebar,
    setIsChevronSidebarButtonDisabled,
  } = useTabbedSidebar();
  const layerTitle = searchEntitiesProperties?.layer_title || "Substations";

  const admEntitiesLayerTitle =
    admLevelEntities?.properties.layer_title || "County";

  useEffect(() => {
    if (isLoadingOutputLands && showModal) {
      closeSidebar();
      setActiveTab(1);
      setIsChevronSidebarButtonDisabled(true);
    } else {
      setIsChevronSidebarButtonDisabled(false);
    }
  }, [showModal, isLoadingOutputLands]);

  useEffect(() => {
    if (!isLoadingOutputLands && !!search && !loadSearch) {
      openSidebar();
    }
  }, [search, loadSearch, isLoadingOutputLands]);

  const handleSubmit = () => {
    if (
      selectedSubregion !== undefined &&
      projectType !== undefined &&
      projectSizeInMW !== undefined &&
      searchEntity !== undefined &&
      !loadSearch //Need to blacklist the isLoading for outputlands with values
    ) {
      onSubmit({
        technology: optionsTechnology[projectType],
        searchEntity: optionsSearchEntities[searchEntity],
        subRegion: optionsSubregion[selectedSubregion],
        // projectSize & projectSizeUnit handled in hook!
      });

      isSearching(true);
      navigate(ROUTES.MAPPAGE);
      setShowModal(true);
      setOpenSize(false);
    }
  };

  if (!search) {
    return (
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          width: "70vw",
          minWidth: "800px",
          padding: "16px 8px 0px 16px",
          borderRadius: "16px",
          gap: "16px",
          marginBottom: "16px",
          justifyContent: "space-between",
        }}
      >
        {isLoadingOutputLands && showModal && (
          <LoadingResults setClose={setShowModal} />
        )}
        <SelectSearch
          val={selectedSubregion}
          changeVal={sSubregion}
          textHeader={"Region"}
          textSelect={"Select the region"}
          items={optionsSubregion}
        />
        <SelectSearch
          val={projectType}
          changeVal={sProjectType}
          textHeader={"Type of Project"}
          textSelect={"Select project type"}
          items={optionsTechnology}
        />

        {optionsSearchEntitiesAdmLevel1?.length > 0 && (
          <>
            <AutocompleteSearch
              val={searchEntityAdmLevel1Value}
              changeVal={(newVal) =>
                handleAutocompleteEntity(
                  newVal,
                  optionsSearchEntitiesAdmLevel1,
                  true,
                  true
                )
              }
              textHeader={admEntitiesLayerTitle}
              textSelect={`Select ${admEntitiesLayerTitle}`}
              items={optionsSearchEntitiesAdmLevel1}
            />
          </>
        )}
        <AutocompleteSearch
          val={searchEntity}
          changeVal={(newVal) =>
            handleAutocompleteEntity(newVal, optionsSearchEntities, false, true)
          }
          textHeader={layerTitle}
          textSelect={`Select ${
            layerTitle === "Industrial Areas" ? "Industrial Area" : layerTitle
          }`}
          items={optionsSearchEntities}
          small={search}
          load={searchEntitiesLoader}
        />

        <ProjectSize
          small={search}
          watts={projectSizeInMW}
          setWatts={sProjectSizeInMW}
          acres={acre}
          setAcres={sAcre}
          selected={openSize}
          setSelected={setOpenSize}
          projectType={projectType}
          config={projectSizeConfig}
          sizeUnit={sizeUnit}
          toggleSizeUnit={toggleSizeUnit}
        />

        <Box
          sx={{
            height: "48px",
            width: "48px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#52975C",
            borderRadius: "6px",
            marginTop: "8px",
            cursor: "pointer",
          }}
          onClick={handleSubmit}
        >
          <SearchIcon
            sx={{
              color: "white",
              height: "24px",
              width: "24px",
              position: "relative",
            }}
          />
        </Box>
      </Paper>
    );
  }
  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        width: !search ? "70vw" : null,
        minWidth: !search ? "800px" : null,
        padding: !search ? "16px 8px 0px 16px" : "8px 8px 8px 16px",
        borderRadius: !search ? "16px" : "8px",
        gap: !search ? "16px" : "12px",
        marginBottom: !search ? "16px" : null,
        justifyContent: "space-between",
        alignItems: search ? "center" : null,
      }}
    >
      {isLoadingOutputLands && showModal && (
        <LoadingResults setClose={setShowModal} />
      )}
      <SelectSearch
        val={selectedSubregion}
        changeVal={sSubregion}
        textHeader={"Region"}
        textSelect={"Select the region"}
        items={optionsSubregion}
        small={search}
      />
      <SelectSearch
        val={projectType}
        changeVal={sProjectType}
        textHeader={"Type of Project"}
        textSelect={"Select project type"}
        items={optionsTechnology}
        small={search}
      />

      {optionsSearchEntitiesAdmLevel1?.length > 0 && (
        <AutocompleteSearch
          val={searchEntityAdmLevel1Value}
          changeVal={(newVal) =>
            handleAutocompleteEntity(
              newVal,
              optionsSearchEntitiesAdmLevel1,
              true,
              false
            )
          }
          textHeader={admEntitiesLayerTitle}
          textSelect={`Select ${admEntitiesLayerTitle}`}
          items={optionsSearchEntitiesAdmLevel1}
          small={search}
        />
      )}
      <AutocompleteSearch
        val={searchEntity}
        changeVal={(newVal) =>
          handleAutocompleteEntity(newVal, optionsSearchEntities, false, true)
        }
        textHeader={layerTitle}
        textSelect={`Select ${
          layerTitle === "Industrial Areas" ? "Industrial Area" : layerTitle
        }`}
        items={optionsSearchEntities}
        small={search}
        load={searchEntitiesLoader}
      />

      <ProjectSize
        small={search}
        watts={projectSizeInMW}
        setWatts={sProjectSizeInMW}
        acres={acre}
        setAcres={sAcre}
        selected={openSize}
        setSelected={setOpenSize}
        projectType={projectType}
        config={projectSizeConfig}
        sizeUnit={sizeUnit}
        toggleSizeUnit={toggleSizeUnit}
      />

      <Box
        sx={{
          height: "48px",
          width: "48px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#52975C",
          borderRadius: "6px",
          marginTop: "8px",
          cursor:
            searchEntity !== undefined &&
            selectedSubregion !== undefined &&
            projectType !== undefined
              ? "pointer"
              : "",
          opacity:
            searchEntity !== undefined &&
            selectedSubregion !== undefined &&
            projectType !== undefined
              ? 1
              : 0.5,
        }}
        onClick={handleSubmit}
      >
        {searchEntitiesLoader || isLoadingSearchInputs ? (
          <StyledSpinner
            style={{
              height: "24px",
              width: "24px",
              position: "relative",
              color: "white",
            }}
          />
        ) : (
          <SearchIcon
            sx={{
              color: "white",
              height: "24px",
              width: "24px",
              position: "relative",
            }}
          />
        )}
      </Box>
    </Paper>
  );
};

export default SearchBar;
