import { Typography } from "@mui/material";
import Loader from "../../images/LoadResults.gif";
import { BoxContainer } from "../../styles/AnalyzeLand/LoadingUpload.styles";

const LoadingMessage = ({ title, showLoader }) => {
  return (
    <BoxContainer>
      <Typography>{title}</Typography>
      {showLoader ? (
        <img src={Loader} alt="Load Results" width={"140px"} />
      ) : null}
    </BoxContainer>
  );
};

export default LoadingMessage;
