import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { useAnalyzeLand } from "../../new_hooks/useAnalyzeLand";
import {
  AcordeonsWrapper,
  ShareButton,
  SidebarHeader,
  SidebarHeaderTitle,
  StyledAccordion,
  StyledDrawer,
  StyledReportButton,
  UploadButton,
  ValueStyled,
} from "../../styles/AnalyzeLand/AnalyzeLandSidebar.styles";
import {
  ExpandMore,
  FileUploadRounded,
  MailOutline,
  Share,
} from "@mui/icons-material";
import { useState } from "react";
import Report from "../Report";

const AnalyzeLandSidebar = () => {
  const [genReport, setGenReport] = useState(false);
  const [share, setShared] = useState(false);
  const { setAnalyzeLandModalOpen, analysisResultPoll } = useAnalyzeLand();

  const DISPLAY_KEYS = [
    "technology",
    "ac_score",
    "megawattcapacity",
    "solar_energy_production",
  ];

  const result =
    analysisResultPoll?.status === "success"
      ? JSON.parse(analysisResultPoll?.result)
      : {};
  function formatValue(value) {
    if (typeof value === "number") {
      return value.toFixed(2);
    }
    return value;
  }
  return (
    <>
      <StyledDrawer variant="persistent" anchor="left" open>
        <UploadButton onClick={() => setAnalyzeLandModalOpen(true)}>
          <FileUploadRounded /> Upload File
        </UploadButton>
        {analysisResultPoll?.status === "success" ? (
          <>
            <SidebarHeader>
              <SidebarHeaderTitle>Land Analysis Result</SidebarHeaderTitle>
              <Box className="header-buttons">
                <StyledReportButton
                  variant="long"
                  onClick={() => setGenReport(!genReport)}
                >
                  <MailOutline fontSize="22px" />
                  <Typography>Generate Report </Typography>
                </StyledReportButton>
                <ShareButton
                  variant="outlined"
                  onClick={() => setShared(!share)}
                >
                  <Share />
                </ShareButton>
              </Box>
            </SidebarHeader>

            <Box className="general-info">
              <Typography className="title">General Information</Typography>
              {Object.entries(result)
                .filter(([key]) =>
                  DISPLAY_KEYS.includes(key.replace(/\s/g, "").toLowerCase())
                )
                .map(([key, value]) => (
                  <ValueStyled key={key}>
                    {key.replace(/_/g, " ")}: {formatValue(value)}
                  </ValueStyled>
                ))}
            </Box>
            <AcordeonsWrapper>
              <StyledAccordion disableGutters elevation={0}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">Assessment Parameters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(result.assessment_parameter).map(
                    ([key, value]) => (
                      <ValueStyled key={key} style={{ marginBottom: "8px" }}>
                        {key.replace(/_/g, " ")}: {formatValue(value)}
                      </ValueStyled>
                    )
                  )}
                </AccordionDetails>
              </StyledAccordion>
              <StyledAccordion disableGutters elevation={0}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">Exclusion Parameters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(result.exclusion_parameters).map(
                    ([key, value]) => (
                      <ValueStyled key={key} style={{ marginBottom: "8px" }}>
                        {key.replace(/_/g, " ")}: {formatValue(value)}
                      </ValueStyled>
                    )
                  )}
                </AccordionDetails>
              </StyledAccordion>
            </AcordeonsWrapper>
          </>
        ) : null}

        {(genReport || share) && (
          <Report
            setOpen={genReport ? setGenReport : setShared}
            overlayColor="rgba(0, 0, 0, 0.0)"
            customStyles={{
              top: "35vh",
              left: "50vw",
              boxShadow: 2,
            }}
            //data={landData}
          />
        )}
      </StyledDrawer>
    </>
  );
};

export default AnalyzeLandSidebar;
