import React, { useState } from "react";
import { Box, Button } from "@mui/material";

import { useSearchLandResults } from "../hooks/searchLandResultsHook";

import MapComp from "../new_components/MapComp";

import { useMap } from "../new_hooks/useMap";
import Modal from "../components/Modal";
import SiteResults from "../new_components/SiteResults";

import ReadMore from "../new_components/ReadMore";
import { useCollections } from "../new_hooks/useCollections";
import { useSearchControls } from "../new_hooks/useSearchControls";
import PageLayout from "../components/PageLayout";
import useAuth0AccessToken from "../hooks/authHook";
import { ROUTES } from "../routes/constants";
import { AnimatePresence } from "framer-motion";
import {
  StyledMotionAnalyzeLand,
  StyledMotionWrapperDiv,
} from "../styles/MapPage.styles";
import useLandSearchResults from "../new_hooks/useLandSearchResults";
import AnalyzeLandSidebar from "../new_components/AnalyzeLand/AnalyzeLandResultSidebar";
import { useAnalyzeLand } from "../new_hooks/useAnalyzeLand";
import { useTabbedSidebar } from "../new_hooks/useTabbedSidebar";
import TabbedSideBar from "../new_components/TabbedSideBar";

const MapPage = () => {
  const [selectedLand, setSelectedLand] = useState(-1);
  const [readMore, setReadMore] = useState(false);
  const {
    outputLandsResponse,
    modalMessage,
    shouldShowModal,
    shouldShowQuestionInModal,
    onCloseModal,
    isLoadingOutputLands,
    setQuestion,
    handleNoClick,
    handleYesClick,
  } = useSearchLandResults();
  const { search, tab, sTab, sSearch } = useSearchControls();
  const {
    outputlandsData,
    viewState,
    mapRef,
    onMapClick,
    setViewState,
    onSideChange,
    sideRef,
  } = useMap(setSelectedLand, setReadMore, readMore);
  const { userCollection, aCollection, addToFolder } = useCollections();
  const { accessToken } = useAuth0AccessToken();
  const isHomePageRoute = window.location.pathname.endsWith(ROUTES.SEARCH);
  const { landSearches } = useLandSearchResults(accessToken, isHomePageRoute);
  const { isSidebarOpen } = useTabbedSidebar();
  const { showAnalyzeLandResultsUI, isAnalyzeLandModalOpen } = useAnalyzeLand();

  const handleSelectedLand = (inp) => {
    setSelectedLand(inp);
    onSideChange(inp);
  };

  const sidebarVariants = {
    hidden: {
      x: "-100%",
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
      },
    },
    exit: {
      x: "-100%",
      opacity: 0,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  return (
    <PageLayout
      tab={tab}
      changeTab={sTab}
      search={search}
      isSearching={sSearch}
      setViewState={setViewState}
    >
      <AnimatePresence>
        {isSidebarOpen ? (
          <StyledMotionWrapperDiv
            key="sideBar"
            variants={sidebarVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            isHomePageRoute={isHomePageRoute}
          >
            <TabbedSideBar
              landSearches={landSearches}
              isHomePageRoute={isHomePageRoute}
            >
              {outputLandsResponse?.length > 0 &&
                search &&
                !isLoadingOutputLands &&
                (!readMore ? (
                  <SiteResults
                    outputLandsResponse={outputLandsResponse}
                    handleSelectedLand={handleSelectedLand}
                    sideRef={sideRef}
                    collectionData={userCollection}
                    addCollection={aCollection}
                    addToFolder={addToFolder}
                    selectedLand={selectedLand}
                    setReadMore={setReadMore}
                    setQuestion={setQuestion}
                  />
                ) : (
                  <ReadMore
                    setReadMore={setReadMore}
                    landID={selectedLand}
                    outputLandsResponse={outputLandsResponse}
                    collectionData={userCollection}
                    addCollection={aCollection}
                    addToFolder={addToFolder}
                  />
                ))}
            </TabbedSideBar>
          </StyledMotionWrapperDiv>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {showAnalyzeLandResultsUI &&
        !search &&
        isHomePageRoute &&
        !isAnalyzeLandModalOpen ? (
          <StyledMotionAnalyzeLand
            key="analyzeLandSideBar"
            variants={sidebarVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            zIndex={1000}
          >
            <AnalyzeLandSidebar />
          </StyledMotionAnalyzeLand>
        ) : null}
      </AnimatePresence>

      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: "100%",
          height: "90vh",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            flexGrow: "1",
            overflowX: "hidden",
            overflowY: "hidden",
            flex: 1,
          }}
        >
          <MapComp
            landID={selectedLand}
            setLandID={setSelectedLand}
            search={search}
            outputlandsData={outputlandsData}
            viewState={viewState}
            mapRef={mapRef}
            onMapClick={onMapClick}
            setViewState={setViewState}
            isLoadingOutputLands={isLoadingOutputLands}
          />
        </Box>
      </Box>

      {shouldShowModal && (
        <Modal isOpen={shouldShowModal} onClose={() => onCloseModal()}>
          {!shouldShowQuestionInModal ? (
            <>
              <h2>Information</h2>
              <p>{modalMessage}</p>
            </>
          ) : (
            <>
              <h2>Read More?</h2>
              <p>{modalMessage}</p>
              <Box
                sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="contained"
                  onClick={handleYesClick}
                  sx={{
                    backgroundColor: "#52975C",
                    "&:hover": { backgroundColor: "#47784E" },
                    flex: 1,
                    mx: 1,
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNoClick}
                  sx={{
                    backgroundColor: "#52975C",
                    "&:hover": { backgroundColor: "#47784E" },
                    flex: 1,
                    mx: 1,
                  }}
                >
                  No
                </Button>
              </Box>
            </>
          )}
        </Modal>
      )}
    </PageLayout>
  );
};

export default MapPage;
