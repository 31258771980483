import React from "react";
import { Box, Typography, Checkbox } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Folder from "./Folder";
import { useState } from "react";
import CreateModal from "./CreateModal";
import { Done } from "./Done";
const AddToCollectionModal = ({
  setOpen,
  landID,
  setAddCollection,
  collectionData,
  addToFolder,
}) => {
  const [val, setVal] = useState(-1);

  //const {saveAsFavoriteLand} = useFavoriteLandResults();

  const handleDone = () => {
    addToFolder(val, landID);
    setOpen(false);
  };

  const handleClick = () => {
    setAddCollection(true);
    setOpen(false);
  };

  const check = (inp) => {
    if (val !== inp) {
      setVal(inp);
    } else {
      setVal(-1);
    }
  };

  //Need to prevent collections with the same name - make it a primary key

  return (
    <CreateModal
      height={""}
      width={"450px"}
      setOpen={setOpen}
      overlayHeight={"100vh"}
      overlayWidth={"100vw"}
      customStyles={{
        top: "35vh",
        left: "50vw",
        boxShadow: 2,
      }}
      overlayColor="rgba(0, 0, 0, 0.5)"
      overlayTop={"-105px"}
      content={
        <Box>
          <Box>
            <Typography
              sx={{ fontSize: "24px", fontWeight: 700, textAlign: "center" }}
            >
              {" "}
              Add to collection{" "}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0px",
              marginTop: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "12px",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
              onClick={handleClick}
            >
              <Box
                sx={{
                  borderRadius: "4px",
                  backgroundColor: "#D5E6D8",
                  width: "81px",
                  height: "51px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AddCircleOutlineIcon sx={{ color: "#52975C" }} />
              </Box>
              <Typography
                sx={{ color: "#212121", fontSize: "16px", fontWeight: 500 }}
              >
                {" "}
                Create new collection{" "}
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "60px" }}>
              {collectionData?.map((inp, index) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    curosr: "pointer",
                    padding: "8px",
                    cursor: "pointer",
                    borderRadius: "8px",
                    boxSizing: "border-box",
                    border:
                      inp.id === val
                        ? "2px solid #52975C"
                        : "2px solid transparent",
                  }}
                  onClick={() => check(inp.id)}
                >
                  <Folder
                    inp={inp}
                    fDirection={"row"}
                    changeCollection={() => {}}
                  />
                  <Checkbox
                    checked={inp.id === val}
                    sx={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "5.33px",
                      color: "#52975C",
                      "&.Mui-checked": { color: "#52975C" },
                    }}
                  />
                </Box>
              ))}
            </Box>

            <Done val={val} handleDone={handleDone} />
          </Box>
        </Box>
      }
    />
  );
};

export default AddToCollectionModal;
