import React from "react";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { hasGeometries } from "../utils/functions";
import SiteCard from "./SiteCard";
import { ChevronLeft } from "@mui/icons-material";
import { useTabbedSidebar } from "../new_hooks/useTabbedSidebar";
import {
  SidebarHeader,
  SidebarHeaderTitle,
} from "../styles/LandSearchSideBar/LandSearchSideBar.styles";
const SiteResults = ({
  outputLandsResponse,
  handleSelectedLand,
  sideRef,
  collectionData,
  addCollection,
  addToFolder,
  selectedLand,
  setReadMore,
  setQuestion,
}) => {
  //TODO: Fix: Consume it from setLockedSearchEntity, instead.
  const geometriesExist = hasGeometries(outputLandsResponse);
  const { closeSidebar } = useTabbedSidebar();
  return (
    <Box
      sx={{
        flex: "0 0 40%",
        maxWidth: "620px",
        display: "flex",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "80vh",
          overflow: "hidden",
        }}
      >
        <SidebarHeader>
          <Box>
            <SidebarHeaderTitle variant="h6" gutterBottom>
              Search Results
            </SidebarHeaderTitle>
            <Typography variant="body2">
              {" "}
              {outputLandsResponse?.length} Results
            </Typography>
          </Box>
          <Tooltip title="Close Sidebar">
            <IconButton sx={{ marginRight: 3 }} onClick={closeSidebar}>
              <ChevronLeft />
            </IconButton>
          </Tooltip>
        </SidebarHeader>

        {!geometriesExist && (
          <Button onClick={setQuestion}>Unlock substation</Button>
        )}
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            overflowY: "auto",
            flexGrow: 1,
            padding: "1rem",
          }}
        >
          {outputLandsResponse
            .sort((a, b) => b.ac_score - a.ac_score)
            .map((landData, index) => {
              return (
                <div
                  key={index}
                  ref={sideRef.current[landData.id]}
                  onClick={() => handleSelectedLand(landData.id)}
                >
                  <SiteCard
                    landData={landData}
                    number={index}
                    collectionData={collectionData}
                    addCollection={addCollection}
                    addToFolder={addToFolder}
                    borderLand={selectedLand === landData.id}
                    setReadMore={setReadMore}
                  />
                </div>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default SiteResults;
