import React, { useEffect, useMemo } from "react";
import { LayerMenu } from "../styles/MapLibreScreen.styled";
import Map, { AttributionControl } from "react-map-gl/maplibre";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "maplibre-gl/dist/maplibre-gl.css";
import { LayerControls, DynamicLayers } from "../components";
import StaticLayers from "./StaticLayers";
import { Source, Layer } from "react-map-gl/maplibre";
import { Box, Typography, Tooltip } from "@mui/material";
import SelectFilter from "./SelectFilter";
import LayerIcon from "../images/icons/Layers.png";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Satellite from "../images/Satellite.png";
import MapSty from "../images/Map.png";
import MapStyle from "./MapStyle";
import { useSelector } from "react-redux";
import { ROUTES } from "../routes/constants";
import { ChevronRight } from "@mui/icons-material";
import {
  StyledChevronRightIcon,
  StyledMotionBox,
  WrapperSelectFilter,
} from "../styles/MapScreen.styled";
import useLandSearchResults from "../new_hooks/useLandSearchResults";
import { selectMapStyle } from "../redux/selectors/mapSelectors";
import { useAnalyzeLand } from "../new_hooks/useAnalyzeLand";
import { useTabbedSidebar } from "../new_hooks/useTabbedSidebar";
import { useLayers } from "../new_hooks/useLayers";

const MapScreen = ({
  mapRef,
  fillData,
  lineData,
  onMapClick,
  viewState,
  layers,
  changeLayer,
  setViewState,
  search,
  isLoadingOutputLands,
}) => {
  const [selected, setSelected] = useState(false);
  const mapStyle = useSelector(selectMapStyle);
  const [sessionToken, setSessionToken] = useState(null);

  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const GOOGLE_MAP_TILE_BASE_URL = "https://tile.googleapis.com/v1";
  const isMapPageRoute = window.location.pathname.endsWith(ROUTES.MAPPAGE);

  const { landSearchesStatus } = useLandSearchResults();
  const { isSidebarOpen, openSidebar, isChevronButtonDisabled } =
    useTabbedSidebar();

  const {
    isAnalyzeLandModalOpen,
    showAnalyzeLandResultsUI,
    geojsonDataAnalyzeLand,
  } = useAnalyzeLand({ mapRef });

  const { layersSource } = useLayers();

  const selectsGroupVariants = useMemo(
    () => ({
      open: {
        left: "550px",
        transition: {
          type: "spring",
          stiffness: 300,
          damping: 30,
        },
      },
      closed: {
        left: search || isMapPageRoute ? "24px" : "54px",
        transition: {
          type: "spring",
          stiffness: 300,
          damping: 30,
        },
      },
    }),
    [search, isMapPageRoute]
  );

  const maps = {
    satellite: {},
    map: "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json",
    googleMaps: {},
  };

  const layerelements = Object.keys(layers).map((category) => {
    return Object.keys(layers[category]).map((name) => {
      if (layers[category][name]["active"]) {
        return (
          <Source
            key={`${name}-source`}
            id={`${name}-source`}
            type="raster"
            tiles={[layers[category][name].tileUrl]}
            tileSize={256}
          >
            <Layer
              key={`${name}-layer`}
              id={`${name}-layer`}
              type="raster"
              source={`${name}-source`}
            />
          </Source>
        );
      } else {
        return <React.Fragment key={`${category}-${name}-empty`} />;
      }
    });
  });

  useEffect(() => {
    if (mapRef.current && mapRef.current.getMap) {
      const map = mapRef.current.getMap();

      const reapplyLayers = () => {
        Object.keys(layers).forEach((category) => {
          Object.keys(layers[category]).forEach((name) => {
            if (layers[category][name]["active"]) {
              const sourceId = `${name}-source`;
              const layerId = `${name}-layer`;

              // Adds source if not present
              if (!map.getSource(sourceId)) {
                const tileUrl =
                  mapStyle === "google-maps"
                    ? `${GOOGLE_MAP_TILE_BASE_URL}/2dtiles/{z}/{x}/{y}?session=${sessionToken}&key=${API_KEY}`
                    : layers[category][name].tileUrl;

                map.addSource(sourceId, {
                  type: "raster",
                  tiles: [tileUrl],
                  tileSize: 256,
                });
              }

              // Adds layer if not present
              if (!map.getLayer(layerId)) {
                const beforeLayer =
                  mapStyle === "satellite"
                    ? "esa-eox-layer"
                    : "google-satellite-layer";

                map.addLayer(
                  {
                    id: layerId,
                    type: "raster",
                    source: sourceId,
                    paint: {
                      "raster-opacity": 1,
                    },
                  },
                  beforeLayer
                );
              }
            }
          });
        });

        Object.keys(layers).forEach((category) => {
          Object.keys(layers[category]).forEach((name) => {
            const layerId = `${name}-layer`;
            if (map.getLayer(layerId)) {
              map.moveLayer(layerId);
            }
          });
        });
      };

      map.on("styledata", reapplyLayers);

      return () => {
        map.off("styledata", reapplyLayers);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStyle, layers]);

  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (map && map.getLayer("land-polygons-fill")) {
      const layers = map.getStyle().layers;
      const landPolygonsFillIndex = layers.findIndex(
        (layer) => layer.id === "land-polygons-fill"
      );

      const layerId =
        mapStyle === "satellite"
          ? "esa-eox-layer"
          : mapStyle === "google-maps"
          ? "google-satellite-layer"
          : null;

      if (layerId && map.getLayer(layerId)) {
        map.moveLayer(layerId, layers[landPolygonsFillIndex]?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStyle]);

  //SESSION BASED GOOGLE TILES IMPLEMENTATION

  useEffect(() => {
    const fetchSessionToken = async () => {
      try {
        const tokenData = await createSessionToken();
        setSessionToken(tokenData.session);
      } catch (error) {
        console.error("Error creating Google Maps session token:", error);
      }
    };

    fetchSessionToken();
  }, []);

  const createSessionToken = async () => {
    const response = await fetch(
      `${GOOGLE_MAP_TILE_BASE_URL}/createSession?key=${API_KEY}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          mapType: "satellite",
          language: "en-US",
          region: "US",
        }),
      }
    );

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error.message);
    }
    return json;
  };

  const handleTileError = async (e) => {
    if (e.sourceId === "google-satellite-source") {
      console.warn(
        "Tile error detected. Attempting to refresh session token..."
      );
      try {
        const tokenData = await createSessionToken();
        setSessionToken(tokenData.session);

        if (mapRef) {
          mapRef.getMap().removeSource("google-satellite-source");
          mapRef.getMap().addSource("google-satellite-source", {
            type: "raster",
            tiles: [
              `${GOOGLE_MAP_TILE_BASE_URL}/2dtiles/{z}/{x}/{y}?session=${tokenData.session}&key=${API_KEY}`,
            ],
            tileSize: 256,
          });
          mapRef.getMap().addLayer({
            id: "google-satellite-layer",
            type: "raster",
            source: "google-satellite-source",
          });
        }
      } catch (error) {
        console.error("Failed to refresh session token:", error);
      }
    }
  };
  // rerender of layers after they're being adjusted/reordered/changed when switching between mapStyles
  useEffect(() => {}, [layers]);

  const formattedAttributions = useMemo(() => {
    if (layersSource.length > 0) {
      const sourcesString = layersSource.join(" | ");
      return `Layers source: ${sourcesString}`;
    }
    return "";
  }, [layersSource]);

  return (
    <Map
      ref={mapRef}
      {...viewState}
      onMove={(evt) => setViewState(evt.viewState)}
      // onLoad={() => setMapLoaded(true)} //Useful for drawing. Uncomment if needed.
      style={{ width: "100vw", height: "100vh" }} // Set width and height to cover full viewport
      mapStyle={maps.map}
      onClick={onMapClick}
      onError={handleTileError} //google maps token session expired check
    >
      <AttributionControl
        compact
        position="bottom-right"
        customAttribution={formattedAttributions}
        style={{
          position: "fixed",
          bottom: "0px",
          right: "0px",
        }}
        key={layersSource.join()} // Force re-render
      />
      {mapStyle === "satellite" ? (
        <>
          <Source
            id="esa-eox-source"
            type="raster"
            tileSize={256}
            tiles={[
              "https://tiles.maps.eox.at/wmts?layer=s2cloudless-2020_3857&style=default&tilematrixset=GoogleMapsCompatible&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix={z}&TileCol={x}&TileRow={y}",
            ]}
          >
            <Layer
              id="esa-eox-layer"
              type="raster"
              source="esa-eox-source"
              minzoom={0}
              maxzoom={22}
            />
          </Source>

          <AttributionControl
            key={layersSource.join()}
            compact
            position="bottom-right"
            customAttribution={`Satellite imagery Rendering © <a href="https://s2maps.eu">Sentinel-2 cloudless - https://s2maps.eu</a> by <a href="https://eox.at/">EOX IT Services GmbH</a> (Contains modified Copernicus Sentinel data 2018-2023)<br />${formattedAttributions}`}
            style={{
              position: "fixed",
              bottom: "0",
              right: "0px",
            }}
          />
        </>
      ) : null}
      {mapStyle === "google-maps" && sessionToken ? (
        <>
          <Source
            id="google-satellite-source"
            type="raster"
            tileSize={256}
            tiles={[
              `${GOOGLE_MAP_TILE_BASE_URL}/2dtiles/{z}/{x}/{y}?session=${sessionToken}&key=${API_KEY}`,
            ]}
          >
            <Layer id="google-satellite-layer" type="raster" />
          </Source>
          <AttributionControl
            compact
            position="bottom-right"
            key={layersSource.join()}
            customAttribution={
              layersSource.length !== 0
                ? `Google Maps<br />${formattedAttributions}`
                : "Google Maps"
            }
            style={{
              position: "fixed",
              bottom: "0",
              right: "0px",
            }}
          />
        </>
      ) : null}

      <StyledMotionBox
        variants={selectsGroupVariants}
        initial={isSidebarOpen ? "open" : "closed"}
        animate={isSidebarOpen ? "open" : "closed"}
        transition={
          search && isMapPageRoute
            ? { duration: 0 }
            : { type: "spring", stiffness: 300, damping: 30 }
        }
      >
        {isSidebarOpen ? null : (
          <Tooltip title="Open Sidebar">
            <span>
              <StyledChevronRightIcon
                disabled={
                  isChevronButtonDisabled || landSearchesStatus !== "success"
                }
                onClick={openSidebar}
                isMapPageRoute={isMapPageRoute}
                isAnalyzeLandOpen={isAnalyzeLandModalOpen}
                showAnalyzeLandResultsUI={showAnalyzeLandResultsUI}
              >
                <ChevronRight />
              </StyledChevronRightIcon>
            </span>
          </Tooltip>
        )}
      </StyledMotionBox>

      <WrapperSelectFilter
        isSidebarOpen={isSidebarOpen}
        showAnalyzeLandResultsUI={showAnalyzeLandResultsUI}
        isAnalyzeLandModalOpen={isAnalyzeLandModalOpen}
      >
        {Object.keys(layers).map((category, index) => {
          return (
            <SelectFilter
              category={category}
              changeActive={changeLayer}
              subcategories={layers[category]}
              key={`${category}-${index}`}
            />
          );
        })}
      </WrapperSelectFilter>

      {!selected ? (
        <Box
          sx={{
            position: "fixed",
            top: isMapPageRoute && !!search ? "120px" : "190px",
            right: "24px",
            height: "48px",
            width: "48px",
            backgroundColor: "white",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => setSelected(true)}
        >
          <img src={LayerIcon} height={"24px"} width={"24px"} alt="LayerIcon" />
        </Box>
      ) : (
        <Box
          sx={{
            position: "fixed",
            top: isMapPageRoute && !!search ? "120px" : "190px",
            right: "24px",
            backgroundColor: "white",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "110px",
            padding: "12px",
          }}
        >
          <Box
            sx={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography sx={{ fonstSize: "16px", fontWeight: 500 }}>
              {" "}
              Layers
            </Typography>
            <CloseIcon
              sx={{ cursor: "pointer", color: "black" }}
              onClick={() => setSelected(false)}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
              Visual Style
            </Typography>
            <MapStyle
              iMG={MapSty}
              text={"Map"}
              setter={"base"}
              color="black"
              key={"map"}
            />
            <MapStyle
              iMG={Satellite}
              text={"Google Maps"}
              setter={"google-maps"}
              color="white"
              key={"google-maps"}
            />
            <MapStyle
              iMG={Satellite}
              text={"Satellite"}
              setter={"satellite"}
              color="white"
              key={"satellite"}
            />
          </Box>
        </Box>
      )}
      {!isLoadingOutputLands && search && (
        <StaticLayers fillData={fillData} lineData={lineData} />
      )}

      <>{layerelements} </>
      {!!geojsonDataAnalyzeLand &&
        showAnalyzeLandResultsUI &&
        !isAnalyzeLandModalOpen && (
          <Source
            id="analyzeLandResultSource"
            type="geojson"
            data={geojsonDataAnalyzeLand}
            key={`analyzeLandSource-${mapStyle}`}
          >
            <Layer
              id="analyzeLandResultLayer"
              type="line"
              paint={{
                "line-color": "#52975c",
                "line-width": 2,
                "line-opacity": 0.95,
              }}
              key={`analyzeLandLine-${mapStyle}`}
            />
            <Layer
              id="analyzeLandResultLayerFill"
              type="fill"
              paint={{
                "fill-color": "#52975c",
                "fill-opacity": 0.25,
              }}
              key={`analyzeLandFill-${mapStyle}`}
            />
          </Source>
        )}

      {/*<DynamicLayers
        layersStructure={layersStructure.regions}
        activeLayers={activeLayers}
      />

      <LayerMenu>
        <LayerControls
          layersStructure={layersStructure.regions}
          toggleLayer={toggleLayer}
          activeLayers={activeLayers}
        />
      </LayerMenu>*/}
    </Map>
  );
};

export default MapScreen;
